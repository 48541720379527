import React,{useState, useEffect,useReducer,useCallback} from 'react'
import sessionManager from '../lib/session'
import GameList from './components/GameList'
import SubmitButton from '../common/components/SubmitButton'
import Alert from '../common/components/Alert'
import WeekList from '../common/components/WeekList'

import {get,post} from '../lib/call-api'
import useIsMountedRef from '../common/hooks/useIsMountedRef'

import {initialState,gamesReducer} from './gamesReducer.js'

const SetWinners = () => {
  const [savedGames,setSavedGames] = useState([])
  
  const sessionData = sessionManager.getSession()

  const [winnersWeek,setWinnersWeek] = useState(sessionData.thisWeek)
  
  const [pageLevelError,setPageLevelError] = useState('')
  const [isLoading,setIsLoading] = useState(true)

  const isMounted = useIsMountedRef()

  const [state, dispatch] = useReducer(gamesReducer, initialState);


  useEffect(() => {
    if(winnersWeek) {
      const onGet = (response) => {
        if(isMounted.current === true) {
          setIsLoading(false)
          dispatch({
            "type": "INITALIZE_STATE",
            "games": response.games
          })
        }
      }
      const onError = (error) => {
        if(isMounted.current === true) {
          setIsLoading(false)
        }
      }

      get(`/api/games/list/winners-needed/season/${sessionData.season.seasonId}/week/${winnersWeek.weekId}`,onGet,onError)
    }

    return () => { isMounted.current = false }
  },[winnersWeek,isMounted])
 

  const setWinningTeam = useCallback((gameId, teamId) => {
    dispatch({
      "type": "UPDATE_WINNING_TEAM",
      "gameId": gameId,
      "winningTeamId": teamId
    })
  }, [])

const setTotalPointsScored = useCallback((gameId, totalPointsScored) => {
  dispatch({
    "type": "UPDATE_TOTAL_POINTS",
    "gameId": gameId,
    "totalPointsScored": totalPointsScored
  })
}, [])


const validateForm = () => {
  let haveErrors = false

    state.games.map(game => {
      const errorMessages = validateGame(game)
      if(errorMessages.length > 0) {
        haveErrors = errorMessages.filter(message => message.messageType === "error").length > 0
        dispatch({
          "type": "UPDATE_GAME_ERROR",
          "gameId": game.gameId,
          "errorMessages": errorMessages
        })
      }
    })    

    if(haveErrors === true) {
      setPageLevelError('There are errors with one or more games. See below.')
      return false
    } else {
      return true
    }
  
}

const validateGame = (game) => {

  const gameErrors = []
  if(!game.winningTeamId)  {
    gameErrors.push({
      "messageType": "error",
      "message": "Select the winning team."
    })
  }

  if(game.isTieBreaker === true && !game.totalPointsScored)  {
    gameErrors.push({
      "messageType": "error",
      "message": "Provide total points scored for the tiebreaker."
    })
  }

  return gameErrors
}

const handleSubmit = async (event) => {
  event.preventDefault()
  setIsLoading(true)
  const onPost = (response) => {
    setIsLoading(false)
    setPageLevelError('')
    setSavedGames(response.games)
  }
  const onPostError = (error) => {
    setPageLevelError(error.message)
    setIsLoading(false)
  }
  const reqBody = {
    "games": state.games
  }
  post('/api/games/save',onPost,onPostError,setIsLoading,'save winners',validateForm,reqBody)
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });

}


  return (
    <>
      {
        savedGames.length > 0 && 
        <section>
          <h1>Schedule: Week {winnersWeek.weekNum}</h1>
          <Alert type='success' message={`You have saved the winners for week ${winnersWeek.weekNum}`} />
          <GameList 
            games={savedGames} 
            userAction='winners'
            selectedTeamIdAttribute='winningTeamId' 
          />
        </section>
      }

      {
        savedGames.length === 0 && state.games &&
        <form onSubmit={(event) => {handleSubmit(event)}}>
          <h1>Pick the winning teams</h1>

          <Alert type='error' message={pageLevelError} />
          
          <section className="week-list-container">
            <label>Select a week:</label> <WeekList  seasonId={sessionData.season.seasonId} selectedWeek={winnersWeek} handleChange={setWinnersWeek} />
            {
              state.games.length > 0 && 
              <p>Note: if you change the week you will lose any unsaved changes below.</p>
            }
          </section>

          {
            state.games.length === 0 && 
            <p>No games have started for the selected week.</p>
          }

          {
            state.games.length > 0 && 
            <>
              <GameList 
                games={state.games} 
                userAction='winners'
                selectedTeamIdAttribute='winningTeamId' 
                onTeamSelect={setWinningTeam} 
                onTotalPointsEntry={setTotalPointsScored}
              />
              
              <div className='button-container pinned'>
                <div className='form-status'>
                  Winners selected for {state.games.filter(game => game.winningTeamId !== null).length} of {state.games.length}  games
                </div>

                <div><SubmitButton isLoading={isLoading} />  </div>
              </div>
            </>
          }

          
        </form>
      }
    </>
  )
}

export default SetWinners