import React from 'react'
import {format} from 'date-fns'

const DisplayTime = ({toFormat}) => {
  
  const asDate = new Date(toFormat)
  const formattedTime = format(asDate,'h:mm a')

  return (
    <span className="display-time">
      {formattedTime}
    </span>
  )
}

export default DisplayTime