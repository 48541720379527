import React, { useEffect,useState } from 'react'
import sessionManager from '../../lib/session'
import {get} from '../../lib/call-api'
import utils from '../../lib/util'
import Alert from '../../common/components/Alert'
import '../css/styles.scss'
import useIsMountedRef from '../../common/hooks/useIsMountedRef'

const WeekSummary = () => {
  const sessionData = sessionManager.getSession()
  const [message,setMessage] = useState()
  const [messageType,setMessageType] = useState()

  const [userPicksCorrect,setUserPicksCorrect] = useState()
  
  const [winners,setWinners] = useState()
  const [highScorers, setHighScorers] = useState()
  const [weekNum,setWeekNum] = useState()
  const [isLoading,setIsLoading] = useState(true)
  const [haveResultsForSeason,setHaveResultsForSeason] = useState(false)

  const isMounted = useIsMountedRef()
  useEffect(() => {
    const onGet = (response) => {
      if(isMounted.current === true) {
        setIsLoading(false)
        if(response.weekSummary !== null && response.userSummary !== null) {
          setHaveResultsForSeason(true)
          const winnersLastWeek = response.weekSummary.winners
  
          setMessage()
          setMessageType()
          setUserPicksCorrect(response.userSummary.totalCorrect)
          setWinners(winnersLastWeek)
          setHighScorers(response.weekSummary.highScorers)
          setWeekNum(response.weekSummary.weekNum)
  
        } else {
          setWeekNum(sessionData.thisWeek.weekNum)
        }
      }

    }
  
    const onGetError = (error) => {
      if(isMounted.current === true) {
        setIsLoading(false)
        setMessage(`Couldn't get a summary of last week`)
        setMessageType('error')
      }
    }

    if(sessionData.thisWeek && sessionData.thisWeek.weekId) {
     get(`/api/standings/summary/week`,onGet,onGetError)
    } else {
      setIsLoading(false)
    }

    return () => { isMounted.current = false}

  },[isMounted])



  return (
    <div className="callout">
    
     {
     isLoading === false &&
     <>
      <h3>Week {weekNum} results</h3>
      {
        haveResultsForSeason === false && weekNum &&
        <p className="no-results">No results yet for week {weekNum}</p>

      }

     {

      winners && winners.length > 0 && haveResultsForSeason === true && 
     <>
        <h4>Congratulations to...</h4>
      {
        message && messageType === 'error' && 
        <Alert type='error' message={message} context="inline" />
      }
  
      <div  id="last-week-winner">
        <div id="trophy">
          <i className="fas fa-trophy"></i>
        </div>
        
        <div>
          {
            winners && highScorers && userPicksCorrect &&
           <> 

           
            <div id="winner-name">

              {
                winners.length === 1 && 
                <span> 
                {
                  winners[0].nickname
                }
                </span>
              }

              {
                winners.length > 1 && 
                <span> 
                {
                  winners.map((winner,winnerCtr) => 
                    <>{winner.nickname} {utils.outputSeparator(winners,winnerCtr)}</>
                  )
                  
                }
                </span>
              }

            </div>
            <div>
              
              {
                winners.length === 1 && 
                <> winner</>
              }
              {
                winners.length > 1 && 
                <> winners</>
              }

            </div>
            <div id="winner-total">
              { highScorers[0].totalCorrect } correct
              {
                highScorers.length > winners.length && 
                <span> via tiebreaker</span>
              }
            </div>

           </>
          }
        </div>
      </div>
     
     </>
    
    }
    </>
}
     </div>
  )
}
export default WeekSummary