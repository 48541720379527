import React, { useRef, useEffect, useState  } from 'react'

const SetName = ({id,value, placeholder,type,handleChange,autoFocus,validateFunction = () => {},errorMessage}) => {
    const field = useRef(null);
    useEffect(() => {
        if(autoFocus === true) field.current.focus();
    }, [autoFocus]);

    const [localValue,setLocalValue] = useState((typeof(value) === 'undefined') ? '' : value)
    const changeValue = (newValue) => {
        setLocalValue(newValue)
        handleChange(newValue)
    }
    
    useEffect(() => {
        if(typeof(value) !== 'undefined') setLocalValue(value)
    }, [value])

    return (
        <span>
            <input 
                type={type}
                className={typeof(errorMessage) !== 'undefined' && errorMessage !== '' ? 'form-control field-with-error' : 'form-control'} 
                id={id}
                ref={field}
                value={localValue}
                placeholder={placeholder} 
                onChange={(event) => {changeValue(event.target.value)}}
                onBlur ={() => {validateFunction()}}
                />
            {
                typeof(errorMessage) !== 'undefined' && errorMessage !== '' &&
                    <div className="field-error">
                        <p>{errorMessage}</p>
                    </div>
            }
        </span>
    )
}
export default SetName


