import React, { useState } from "react"
import FormField from '../../common/components/FormField'

const SetPassword = ({setValue,context}) => {
    const [newPassword, setNewPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [displayPassword, setDisplayPassword] = useState(false)

    const updatePassword = (newVal) => {
        setNewPassword(newVal)
    }
    let labelText = "Password"
    if(context && (context === "PASSWORD_RESET" || context === "CHANGE_PASSWORD_NEW")) {
        labelText = "New password"
    } else if(context && (context === "PASSWORD_RESET" || context === "CHANGE_PASSWORD_CURRENT")) {
        labelText = "Current password"
    }
    //const labelText = (context && (context === "PASSWORD_RESET" || context === "CHANGE_PASSWORD")) ? "New password" : "Password"
    const passwordIsValid = () => {
        if(newPassword === '') {
            setErrorMessage(`Please provide a ${labelText.toLowerCase()}.`)
            setValue(null)
            return false
        } 
        setErrorMessage('')
        setValue(newPassword)
        return true
    }

    const togglePasswordDisplay = event => {
        event.preventDefault()
        setDisplayPassword(displayPassword === false)
    }

    return (
        <div className="form-group">
            <div id="password-field">
                <label htmlFor="newPassword">{labelText}</label>  
                <div className="show-password-toggle">
                {
                    displayPassword === false && 
                    <button onClick={togglePasswordDisplay} className="btn btn-link">SHOW</button>
                }
                {
                    displayPassword === true && 
                    <button onClick={togglePasswordDisplay} className="btn btn-link">HIDE</button>
                }  
                </div>
            </div>
            <FormField type={(displayPassword === false) ? 'password' : 'text'} id="password" errorMessage={errorMessage} handleChange={updatePassword} validateFunction={passwordIsValid}/>
            
            {
                /*
                (!context || context !== "CHANGE_PASSWORD_CURRENT") &&
                <small>
                    Password requirements:
                    <div className="row">
                        <div className="col-md-6">
                            <ul>
                                <li>Minimum of eight characters</li>
                                <li>At least one number</li>
                                <li>At least one  special character</li>
                            </ul>

                        </div>
                        <div className="col-md-6">
                            <ul>
                                <li>At least one uppercase letter</li>
                                <li>At least one lowercase letter</li>
                            </ul>
                        </div>
                    </div>
                </small>
                */
            }
            
        </div>
    )
    
}

export default SetPassword