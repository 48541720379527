import React from 'react'
import './TeamIcon.scss'

const TeamLogo = ({id,gameId,teamToDisplay,handleClick,isSelected,actionAllowed}) => {
  let imgPath = '/img/team-logos/default-logo.png'
  let altText = 'Default team logo'
  let cssClass = 'team-logo'
  
  const isClickable = (handleClick && actionAllowed === true && isSelected === false)

  if (teamToDisplay && teamToDisplay.teamId) {
    imgPath = `/img/team-logos/${teamToDisplay.logoFilename}`
    altText = `${teamToDisplay.cityRegion} ${teamToDisplay.mascot} logo`
  }

  const teamSelected = (team) => {
    if(isClickable) handleClick(gameId,team.teamId)
  }

  if(isSelected) cssClass += ' selected'
  if(isClickable) cssClass += ' clickable'

  return (
    <div className={cssClass} id={id} data-testid={id} onClick={() => teamSelected(teamToDisplay)}>
      <img src={imgPath} alt={altText} />
    </div>
  )
}

export default TeamLogo