import React from 'react'

const DisplaySpread = ({id,spread}) => {
  const isPositive = (spread > 0) ? true : false

  let plus = ''
  if(isPositive) {
    plus = '+'
  }

  return (
    <>
    { spread && 
      <span id={id} className='spread'>{plus} {spread}</span>
    }
    { !spread && 
      <span id={id} className='no-spread'>No spread!</span>
    }
    </>
  )
}

export default DisplaySpread