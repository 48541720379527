import React, {useEffect,useState} from 'react'
import sessionManager from '../../lib/session'
import WeekList from '../../common/components/WeekList'
import {get} from '../../lib/call-api'

import './QuizUserSummary.scss'

const QuizUserSummary = ({picks}) => {
  const sessionData = sessionManager.getSession()
  const [picksData,setPicksData] = useState([])
  const [weekToShow, setWeekToShow] = useState()
  const [resultSummary, setResultSummary] = useState()

  useEffect(() => {
    let queryString = ''
    if(weekToShow && weekToShow !== 'all') {
      queryString = `?weekId=${weekToShow.weekId}`
    }
    if(typeof picks === "undefined" && weekToShow) {
      const onGet = (response) => {
        setPicksData(response.picks)
        setResultSummary(response.resultSummary)
      }

      const onGetError = (customError) => {
        console.log(customError)
      }
      get(`/api/picks/quiz/user/${sessionData.userData.userId}${queryString}`,onGet,onGetError,null,'get your quiz selections')
      
    } else if(typeof picks !== "undefined") {
      setPicksData(picks)
    }
  },[picks, weekToShow, sessionData.userData.userId])

  useEffect(() => {
    setWeekToShow(sessionData.thisWeek)
  }, [sessionData.thisWeek])

  const cssClassResult = (pickData) => {
    if (pickData.pointsAwarded > 0) {
      return 'correct'
    } else {
      return 'incorrect'
    }
}

const getSelectedAnswer = (pick) => {
  const selectedAnswer = pick.answers.filter(answer => answer.answerId === pick.selectionId)
  if(selectedAnswer.length === 0) {
    return "No answer"
  } else if(selectedAnswer.length === 1) {
    return selectedAnswer[0].answerText
  }
}

const getCorrectAnswer = (pick) => {
  return pick.answers.filter(answer => answer.isCorrect === true)[0].answerText
}

  return (
      <>
      {
        picksData.length === 0 &&
        <div className='no-results'>
        {
          weekToShow && 
          <>You you didn't answer the quiz in week {weekToShow.weekNum}</>
        }
        <WeekList seasonId={sessionData.season.seasonId} handleChange={setWeekToShow} selectedWeek={weekToShow} allowAll={true}/>
        </div>
      }   
      {       
        picksData.length > 0 && 
        <>
          {
          weekToShow && 
            <>
              <h2>Your picks for week {weekToShow.weekNum}</h2>
              <WeekList seasonId={sessionData.season.seasonId} handleChange={setWeekToShow} selectedWeek={weekToShow} allowAll={true}/>
            </>
          }

          {
            resultSummary &&
             <p>You've received { resultSummary.totalPointsAwarded } of a possible {resultSummary.maxPointsPossible} points .</p>
          }
          <table id="quiz-user-summary">
            <thead>
              <tr>
                <th>Question</th>
                <th>Your answer</th>
                <th>Points available</th>
                <th>Points awarded</th>
              </tr>
            </thead>
            <tbody>
            {
              picksData.map(pick => 
                <tr key={pick.questionId}>
                  <td>{pick.questionText}</td>
                  <td>
                    <div className={cssClassResult(pick)}>{getSelectedAnswer(pick)}</div>
                    {
                      pick.pointsAwarded === 0 &&
                      <div>Correct answer: {getCorrectAnswer(pick)}</div>
                    }
                    </td>
                  <td>{pick.points}</td>
                  <td>{pick.pointsAwarded}</td>
                </tr>  
              )
            }
            </tbody>
          </table>
          
        </>
      }
      </>
  )

}


export default QuizUserSummary