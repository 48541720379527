import React,{useState,useEffect,useReducer,useCallback} from 'react'
import Alert from '../../common/components/Alert'
import GameList from '../../games/components/GameList'
import SubmitButton from '../../common/components/SubmitButton'
import { useLocation } from 'react-router-dom'
import { get,post } from '../../lib/call-api'
import useIsMountedRef from '../../common/hooks/useIsMountedRef'

import {initialState,gamesReducer} from '../../games/gamesReducer.js'

const SelectPicks = ({userMakingPicks,thisWeek, whosPicks, onStatusChange}) => {
  
  const [isLoading, setIsLoading] = useState(true)
  const [pageLevelMessage,setPageLevelMessage] = useState('')
  const [pageLevelMessageType, setPageLevelMessageType] = useState('')
  const [status,setStatus] = useState('editing')
  const location = useLocation()
  const [allAreRestricted,setAllAreRestricted] = useState(true)
  const isMounted = useIsMountedRef()
  const [state, dispatch] = useReducer(gamesReducer, initialState);

  
  useEffect(() => {
    setStatus('editing')
    setPageLevelMessage('')

    const onGetPicks = (response) => {
      if(isMounted.current === true) {
        setIsLoading(false)
        dispatch({
          "type": "INITALIZE_STATE",
          "games": response.picks
        })
        setAllAreRestricted(response.picks.length === response.picks.filter(game => game.allowAction === false).length)
      }
    }
    const onGetPicksError = (customError) => {
      console.log("received error")
      if(isMounted.current === true) {
        setPageLevelMessage(customError.message)
        setPageLevelMessageType('error')
        setIsLoading(false)
      }
    }

      get(`/api/picks/game/user/${userMakingPicks.userId}/week/${thisWeek.weekId}`,onGetPicks,onGetPicksError)
   

    return () => { isMounted.current = false }
    
  },[location,isMounted])

 

  const setSelectedTeam = useCallback((gameId, teamId) => {
      dispatch({
        "type": "UPDATE_PICK",
        "gameId": gameId,
        "selectionId": teamId
      })
    }, [])

  const setTotalPointsScored = useCallback((gameId, totalPointsScored) => {
    dispatch({
      "type": "UPDATE_TOTAL_POINTS",
      "gameId": gameId,
      "totalPointsScored": totalPointsScored
    })
  }, [])

  const validatePick = (game) => {
    const gameErrors = []

    /*
      users don't have to submit all their picks but if they make a pick for the tiebreaker game, 
      they need to provide a pick and a tiebreaker value
    */

    if(game.actionsAllowed === true && game.selectionId !== null && game.isTiebreaker === true && game.totalPointsScored === null)  {
      gameErrors.push({
        "messageType": "error",
        "message": "Provide total points scored for the tiebreaker."
      })
    }

    return gameErrors
  } 

  const validateForm = () => {
    let haveErrors = false

    state.games.map(game => {
      const errorMessages = validatePick(game)
      if(errorMessages.length > 0) {
        haveErrors = errorMessages.filter(message => message.messageType === "error").length > 0
        dispatch({
          "type": "UPDATE_GAME_ERROR",
          "gameId": game.gameId,
          "errorMessages": errorMessages
        })
      }
    })    

    if(haveErrors === true) {
      setPageLevelMessage('There are issues with your picks. Please check below.')
      setPageLevelMessageType('error')
      return false
    } else {
      return true
    }

  }
  
   const handleSubmit = async (event) => {
    event.preventDefault()
    setPageLevelMessage('')
      
    const reqBody  = {
      "picks": state.games,
      "pickType": "game",
      "user": userMakingPicks
    }

    const onPostSuccess = (response) => {
      
      setIsLoading(false)
    
      let localPicksWithErrors = 0
      for(let i=0;i<response.picks.length;i++) {
        if(response.picks[i].errorMessages.length > 0) {
          for(let p=0;p<response.picks[i].errorMessages.length;p++) {
            if(response.picks[i].errorMessages[p].messageType === 'error') {
              localPicksWithErrors = localPicksWithErrors + 1
            }
          }
        }
        
      }

      if(localPicksWithErrors > 0) {
        setPageLevelMessageType('warning')
        setPageLevelMessage(`We couldn't save some of  ${whosPicks(false)} picks. See below for details.`)
      } else {
        setStatus('saved')
        if(onStatusChange) onStatusChange('saved')
        setPageLevelMessageType('success')

        const countOfPicks = response.picks.filter(game => game.selectionId === null)
        const tieBreakerValue = response.picks.filter(game => game.isTiebreaker === true && game.totalPointsScored === null)
        const baseMessage = `${whosPicks(true)} picks have been saved!`

        const picksAdditionalMessage = (countOfPicks.length > 0) ? 'Note: There are still games without picks, make sure you come back and make a pick for all games.' : ''
        
        const tieBreakerAdditionalMessage = (tieBreakerValue.length > 0) ? 'Total points scored were not provided.' : ''

        setPageLevelMessage(`${baseMessage} ${picksAdditionalMessage} ${tieBreakerAdditionalMessage}`)
      } 
      
      
      dispatch({
        "type": "INITALIZE_STATE",
        "games": response.picks
      })

    }

    const onPostError = (error) => {
      setPageLevelMessageType('error')
      setPageLevelMessage(error.message)
      setIsLoading(false)
    }

    post('/api/picks/save',onPostSuccess,onPostError,setIsLoading,'save picks',validateForm,reqBody)
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  }

  return (
    <>
    {
      thisWeek && 
      <>

        {
          pageLevelMessage !== '' && 
            <Alert type={pageLevelMessageType} message={pageLevelMessage} />
          }

        {
          isLoading === false && status === 'editing' &&
          <form onSubmit={(event) => {handleSubmit(event)}}>
               
              {
                state.games && state.games.length > 0 &&
                <>
                  <GameList 
                    games={state.games} 
                    userAction='picks'
                    selectedTeamIdAttribute='selectionId' 
                    onTeamSelect={setSelectedTeam} 
                    onTotalPointsEntry={setTotalPointsScored}
                  />

                {
                  allAreRestricted === false &&
                  <div className='button-container pinned'>
                    <div className='form-status'>
                      Picks made for {state.games.filter(game => game.selectionId !== null).length} of {state.games.length}  games
                    </div>
                    <div>
                      <SubmitButton isLoading={isLoading}/>
                    </div>
                  </div>
                }
                </>
              }
            </form>
        }

        {        
          status !== 'editing' && 
          <GameList games={state.games} userAction='picks' selectedTeamIdAttribute='selectionId' />
        }
      </>
    }
    </>
  )
}
export default SelectPicks