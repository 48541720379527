import React, {useEffect,useState} from 'react'
import User from '../common/components/User'
import SaveMember from './components/SaveMember'
import Modal from '../common/components/Modal'
import { useModalState  } from '../common/components/Modal.Context'
import Alert from '../common/components/Alert' 
import {get} from '../lib/call-api'
import sessionManager from '../lib/session'
import useIsMountedRef from '../common/hooks/useIsMountedRef'

const PlayerList = () => {
  const sessionData = sessionManager.getSession()

  const [userList, setMemberList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  
  const [refresh, setRefresh] = useState(false)
  const [showHiddenContent, setShowHiddenContent] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [savedPlayer, setSavedPlayer] = useState({})

  const [playerEditing, setPlayerEditing] = useState()
  const [, modalDispatch] = useModalState()

  const isMounted = useIsMountedRef()

  const toggleSlider = () => {
    modalDispatch({ type: 'toggleIsOpen', payload: null })
  }

  const updateOnAdd = (data, message) => {
    setRefresh(true)
    setShowHiddenContent(false)
    modalDispatch({"type": "toggleIsOpen"})
    setSuccessMessage(message)
    setSavedPlayer(data)
    setPlayerEditing()
  }

  const handleModalClose = () => {
    setShowHiddenContent(false)
    modalDispatch({"type": "toggleIsOpen"})
    setPlayerEditing()
  }


  const editPlayer = (playerData) => {
    setPlayerEditing(playerData)
    modalDispatch({"type": "toggleIsOpen"})
  }
    
  useEffect(() => {
    const onGet = (resp) => {
      if(isMounted.current === true) {
        setIsLoading(false)
        setMemberList(resp.users)
      }
    }

    get(`/api/users/list/${sessionData.pool.poolId}`,onGet,setErrorMessage)
    return () => { isMounted.current = false}
  }, [refresh,isMounted])
  
  return(
    
    <>
     
        <h1>Players</h1>

        { errorMessage !== '' && (
            <Alert type="error" message={errorMessage} />
        )}
        
          <div className="feature-manager">
              <button className="btn btn-icon btn-add" onClick={toggleSlider}>
                Add a player
              </button>
          </div>  
        
    

      
      <Modal onClose={handleModalClose} show={showHiddenContent}>
        <SaveMember onSuccess={updateOnAdd} playerData={playerEditing}/>
      </Modal>
      { successMessage !== '' && (
            <Alert type="success" message={successMessage} />
        )}
      
      <div>
      {
        isLoading === false && userList && userList.length > 0 && 
        
        <table>
          <thead>
            <tr>
              <th>Nickname</th>
              <th>Name</th>
              <th>Email</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {
              userList.map(user => 
                <tr key={`member${user.userId}`} className={(savedPlayer.userId === user.userId) ? 'alert-success' : ''}>
                  <td>
                    <div className='user-name-with-avatar'>
                      <User userData={user} handleClick={() => { editPlayer(user)}} />
                     
            
                    </div>
                   
                  </td>
                  <td>{ user.firstName} {user.lastName}</td>
                  <td><span  id={`member${user.userId}Email`}>{user.email}</span></td>
            
                  <td>{user.status}</td>
                </tr>
              )
            }
          </tbody>
        </table>
      }
      </div>
    </>
  )
}

export default PlayerList