import React, { useEffect,useState } from 'react'
import {Link} from 'react-router-dom'
import useIsMountedRef from '../../common/hooks/useIsMountedRef'
import sessionManager from '../../lib/session'
import {get} from '../../lib/call-api'
import Alert from '../../common/components/Alert'
import '../css/styles.scss'


const UserSummary = () => {
  const isMounted = useIsMountedRef()
  const sessionData = sessionManager.getSession()
  const [message,setMessage] = useState()
  const [messageType,setMessageType] = useState()

  const [userPicksCorrect,setUserPicksCorrect] = useState()
  
  const [weekNum,setWeekNum] = useState()
  const [weekAverage,setWeekAverage] = useState()
  const [userWasWinner,setUserWasWinner] = useState()

  const [isLoading,setIsLoading] = useState(true)

  useEffect(() => {
    const onGet = (response) => {
      if(isMounted.current === true) {
        setIsLoading(false)
        if(!response.userSummary && !response.weekSummary) {
        
          setMessage("This data is not yet available for the current season.")
          setMessageType("informational")
        } else {
          setMessage()
          setMessageType()
          setUserPicksCorrect(response.userSummary.totalCorrect)
          setWeekNum(response.weekSummary.weekNum)
          setWeekAverage(response.weekSummary.avgPicksCorrect)
          setUserWasWinner(response.weekSummary.winners.filter(winner => winner.userId === sessionData.userData.userId).length > 0)
        }
       
      }
    }
  
    const onGetError = (error) => {
      if(isMounted.current === true) {
        setIsLoading(false)
        setMessage(`Couldn't get a summary of last week`)
        setMessageType('error')
      }
    }

    if(sessionData.thisWeek && sessionData.thisWeek.weekId) {
      get(`/api/standings/summary/week`,onGet,onGetError)
    } else {
      setIsLoading(false)
    }

    return () => { isMounted.current = false}
  },[isMounted])



  return (
  <div className="callout">
      {
        isLoading === false && weekNum &&
        <h3>Your results for week {weekNum}</h3>
      }
      {
        isLoading === false && !weekNum &&
        <h3>Your results for last week</h3>
      }

      {
        message && messageType === 'error' && 
        <Alert type='error' message={message} context="inline" />
      }

      {
        message && messageType === 'informational' && 
        <div>{message}</div>
      }

      {
        !message && userPicksCorrect && userWasWinner !== null && 
        <>
          {
            userWasWinner === false &&
              <div id="user-picks-correct">You had {userPicksCorrect} picks correct in week {weekNum}.</div>
          } 

          {   
            userWasWinner === true &&
              <div id="user-picks-correct">
                You won with {userPicksCorrect} picks correct!
              </div>
          } 

            <div id="pool-average-correct">The average correct for the pool was {weekAverage}.</div>
            <Link to="/standings">See the standings</Link>
        </>
      }
    </div>
  )
}
export default UserSummary