import moment from 'moment'
const authPath = process.env.REACT_APP_AUTH_URI || ""

const sessionManager = () => {
  let sessionTimer
  let tokenTimer

  const initialSession = {
    "userData": null,
    "pool": null,
    "thisWeek": null,
    "season":null,
    "accessToken": null,
    "tokenExpiresOn": null,
    "sessionExpiresOn": null,
    "refreshToken": null
  }
  let sessionData = initialSession

  const startSessionTimer = () => {
    clearInterval(sessionTimer)

    let sessionCountDown = moment(sessionData.sessionExpiresOn).diff(new Date(),'seconds')
    sessionTimer = setInterval(() => {
      //console.log(`session expires (JWT) in ${sessionCountDown}`)
      if(sessionCountDown > 0) {
        sessionCountDown = sessionCountDown - 1
      } else { 
        console.log('session count down at 0')
        clearSession(true)
      }      
    },1000)
    return () => {
      clearInterval(sessionTimer)
    }
  }
  
  const startTokenTimer = () => {
    // this function will make a call to the refresh endpoint when there are REFRESH_ON_SECONDS
    // left until the access token will expire.
    // it allows us to keep the access token alive for API calls on the page

    clearInterval(tokenTimer)

    const REFRESH_ON_SECONDS = 5

    let tokenCountDown = moment(sessionData.tokenExpiresOn).diff(new Date(),'seconds')
    tokenTimer = setInterval(() => {
      if(tokenCountDown > REFRESH_ON_SECONDS + 1) {
        tokenCountDown = tokenCountDown - 1
      } else if(!isNaN(tokenCountDown)) { 
        // refresh the session but don't restart the timer, because the user didn't take any action
        refreshSession(false)
      }      
    },1000)
    return () => {
      clearInterval(tokenTimer)
    }
  }

  const getToken = () => {
    return sessionData.accessToken
  }


  const setSession = (payload,restartSessionTimer) => {
    
    sessionData.userData = payload.userData
    sessionData.pool = payload.pool
    sessionData.thisWeek = payload.thisWeek
    sessionData.season = payload.season
    sessionData.accessToken = payload.accessToken
    sessionData.tokenExpiresOn = payload.tokenExpiresOn
    sessionData.sessionExpiresOn = payload.sessionExpiresOn
    if(restartSessionTimer === true) startSessionTimer()
    startTokenTimer()
  }

  const updateSession = (payload) => {
    const newSession = []
    
    // loop through all the keys in the current session
    Object.entries(sessionData).forEach(keyValueArray => {
      const key = keyValueArray[0]
      const existingValue = keyValueArray[1]
      // if the new payload has that key, use the value in the new payload
      // otherwise use whatever is in session for that key
      const val = (typeof payload[key] !== "undefined") ?
        payload[key]
        :
        existingValue

      newSession[key] = val
    })

    setSession(newSession, false)
  }

  const clearSession = async(isTimeout) => {
    // if the user timed out, the refresh token will already be invalid
    // if the user clicked "logout" we need to explicitly invalidate the refresh
    // token by calling the logout endpoint
    if(isTimeout === false) await fetch(`${authPath}/auth/logout`, { "method": 'delete' })

    const timeoutParam = (isTimeout === true) ? "&timeout=true" : ""
    clearInterval(sessionTimer)
    clearInterval(tokenTimer)
    sessionData = initialSession
    window.location = `/login?redirect=${window.location.pathname}${window.location.search}${timeoutParam}`

  }

  const refreshSession = async (restartSessionTimer) => {
    const handleErrors = async (response) => {
      const asJson = await response.json()

      if(response.status !== 200) {
        throw new Error(asJson.message)
      } else {
        return asJson
      }
    }

    return fetch(`${authPath}/auth/login/refresh-token`, {
      "method": "post",
      "headers": {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      "body": JSON.stringify({
        "refresh": restartSessionTimer === true
      })
    }).then(response => 
      handleErrors(response)
    ).then(response => {
      setSession(response,restartSessionTimer)
      return sessionData
    }).catch(error => {
      console.error('error refreshing session', error)
      clearSession()
    })
  }

  const getSession = () => {
    return sessionData
  }
  return {
      getToken,
      setSession,
      updateSession,
      clearSession,
      refreshSession,
      getSession
  }
};

export default sessionManager()