import React from 'react'
import {format} from 'date-fns'

const DisplayDate = ({dateToFormat, displayFormat}) => {
  const asDate = new Date(dateToFormat)
  const formattedDate = (displayFormat === 'full')  ?
  
  format(asDate,'eeee, MMMM do yyyy')
  :
  format(asDate,'LLL do yyyy')
    
  return (
    <span className='display-date'>
      {formattedDate}
    </span>
  )
}

export default DisplayDate