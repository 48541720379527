import React, { useState } from 'react'
import FormField from '../../common/components/FormField'

const SetConfirmationCode = ({setValue}) => {
    const [confirmationCode, setConfirmationCode] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    
    const confirmationCodeIsValid = () => {
        if(confirmationCode === '') {
            setErrorMessage('Please provide the confirmation code you received.')
            setValue(null)
            return false
        } else if(confirmationCode.length !== 6 || isNaN(confirmationCode)) {
            setErrorMessage('Verification code should be six digits.')
            setValue(null)
            return false
        }
        setErrorMessage('')
        setValue(confirmationCode)
        return true
    }

    return (
        <div className="form-group">
            <p className='intro'>You should have received a confirmacode via email.</p>

            <label htmlFor="confirmation-code">Confirmation code</label>
            <FormField type="text" id="confirmation-code" errorMessage={errorMessage} handleChange={setConfirmationCode} validateFunction={confirmationCodeIsValid}/>
        </div>

    )
}
export default SetConfirmationCode