import React,{useState} from 'react'
import Alert from '../../common/components/Alert'
import utils from '../../lib/util'

const DisplayQuestion = ({questionData,onSave,onCancel}) => {
  const MAX_ANSWERS = 5
  const getDefaultAnswer = () => {
    return {
      'answerId': utils.createTempId(),
      'answerText': null,
      'isCorrect': false
    }

  }

  const answerSetUp = () => {   
    const defaultAnswers = []
    if(questionData && questionData.answers && questionData.answers.length > 0) {
      for(let i=0;i<MAX_ANSWERS;i++) {
        if(i<questionData.answers.length) {
          defaultAnswers.push(questionData.answers[i])
        } else {
          defaultAnswers.push(getDefaultAnswer())
        }
      }
    } else {
      for(let i=0;i<MAX_ANSWERS;i++) {
        defaultAnswers.push(getDefaultAnswer())
      }
    }
   
    return defaultAnswers
  }

  const questionDataToSave = (questionData) ? questionData : {}

  const [newQuestionText,setNewQuestionText] = useState((questionData && questionData.questionText) || '')
  const [newPoints,setNewPoints] = useState((questionData && questionData.points) || 0)
  const [answersToSave,setAnswersToSave] = useState(answerSetUp())
  const [manageQuestionErrors,setManageQuizErrors] = useState([])

  const allDone = (event) => {
    event.preventDefault()
    questionDataToSave.questionText = newQuestionText
    questionDataToSave.points = newPoints
    questionDataToSave.answers = answersToSave.filter(answer => answer.answerText !== null)
    if(isQuestionValid() === true) { 
      onSave(questionDataToSave)
    }
  }

  const isQuestionValid = () => {
    const localErrors = []

    if(questionDataToSave.questionText === '') {
      localErrors.push('Please provide the question text.')
    }

    if(questionDataToSave.points === 0) {
      localErrors.push('Please provide the points allocated to this question.')
    }

    if(answersToSave.filter(answer => answer.answerText !== null).length < 2) {
      localErrors.push('Please provide at least two answers.')
    }

    if(answersToSave.filter(answer => answer.isCorrect === true).length !== 1) {
      localErrors.push('Please provide a correct answer.')
    }
    
    setManageQuizErrors(localErrors)
    return localErrors.length === 0
  }

  const setAnswerText = (event,idx) => {
    const newAnswer = {
      'answerId': answersToSave[idx].answerId,
      'answerText': event.target.value,
      'isCorrect': answersToSave[idx].isCorrect
    }

    setAnswersToSave((previousAnswers) => {    
     return previousAnswers.map((answer,ctr) => {
        if(idx === ctr) {
          return newAnswer
        } else {
          return answer
        }
      })
    })
  }

  const setCorrectAnswer = (idx) => {
    setAnswersToSave((previousAnswers) => {
      return previousAnswers.map((answer,ctr) => {
        return {
          'answerId': answer.answerId,
          'answerText': answer.answerText,
          'isCorrect': (idx === ctr) ? true : false
        }
      })
    })
  }

return (
  <form className="form-inline">
  <div className="manage-quiz-item">
    {
      manageQuestionErrors.length > 0 && 
      <Alert type='error' message={manageQuestionErrors} />
    }
    
    <section id="manage-points">
      <label>How many points?</label>
      <ul className="inline">
        <li><input type="radio" name="pointsValue" checked={newPoints === 3} id="pointsValue3" value="3" onChange={() => {setNewPoints(3)}} /> <label htmlFor="pointsValue3">Three</label></li>
        <li><input type="radio" name="pointsValue" checked={newPoints === 2} id="pointsValue2" value="2" onChange={() => {setNewPoints(2)}} /> <label htmlFor="pointsValue2">Two</label></li>
        <li><input type="radio" name="pointsValue" checked={newPoints === 1} id="pointsValue1" value="1" onChange={() => {setNewPoints(1)}} /> <label htmlFor="pointsValue1">One</label></li>
      </ul>
    </section>
      
    <section id="manage-question-text">
        <label>Enter the question</label>
        <textarea value={newQuestionText} onChange={(event) => setNewQuestionText(event.target.value)} className="quiz-question-input"></textarea>
    </section>
   
    <section id="manage-answers">
      <Answers answersToSave={answersToSave} setAnswerText={setAnswerText} setCorrectAnswer={setCorrectAnswer} />
    </section>

    <div className='button-container pinned'>
        <button className='btn btn-primary' onClick={(event) => allDone(event)}>Save changes</button>
        <button className='btn btn-link' onClick={(event) => onCancel(event)}>Cancel</button>
      </div>
    </div>
    </form>
  )
}

const Answers = ({answersToSave,setAnswerText,setCorrectAnswer}) => {

  return (
    <div className="quiz-answer form-inline">
      <label>Answers <span>(select the correct answer)</span></label>
      {
        answersToSave.map((answer,answerCtr) => 
          <AnswerField  
            idx = {answerCtr}
            answerText={answer.answerText}
            onChange={setAnswerText}
            isCorrect={answer.isCorrect}
            setCorrect={setCorrectAnswer}
            key={`answer${answerCtr}`}
          />
        )
      }
 
  </div>
  )
}


const AnswerField = ({idx,answerText,onChange,isCorrect,setCorrect}) => {
  const handleChange = (event)=> {
    onChange(event,idx)
  }
  const isCorrectLocal = (isCorrect === null) ? false : isCorrect

const trimAnswer = (answerStr) => {
  if(!answerStr || answerStr === "") {
    return answerStr
  } else {
    return answerStr.trim()
  }
}

  return (
    <div className="input-group quiz-selection editing">
      <div>
        <input type='radio' name='isCorrect' value='ans1' checked={isCorrectLocal} onChange={() => setCorrect(idx)} disabled={answerText === null} />
      </div>
      <input name='ans1' type="text" value={trimAnswer(answerText)} onChange={(event) => handleChange(event)} className="form-control"/>      
    </div>
  )
}

export default DisplayQuestion