import React from "react";
import { Switch,Redirect  } from "react-router-dom";
import AppliedRoute from './common/components/AppliedRoute'
import UnauthenticatedRoute from "./common/components/UnauthenticatedRoute"
import AuthenticatedRoute from "./common/components/AuthenticatedRoute"

import Home from './Home';
import NotFound from './common/components/NotFound'

import GamesMain from './games/index'
import ManageGames from './games/manage'
import SetWinners from './games/winners'


import ManagePost from './commishNotes/managePost'

import Standings from './standings'

import PicksMain from './picks/index'
import PickGames from './picks/select-picks'
import OnBehalf from './picks/on-behalf'
import PicksHistory from './picks/history'
import PicksStatusReport from './picks/status-report'

import QuizMain from './quiz/index'
import ManageQuiz from './quiz/manage'
import PickQuiz from './quiz/select-quiz'
import QuizPicksReport from './quiz/user-quiz-report'

import ManageSeason from './season/manage'

import Login from './login'
import ForgotPassword from './login/forgotPassword'

import PlayerList from './users/player-list'

const RootRoute = () => {
  return (<Redirect to={`/home`} />)
}

// setSessionUpdated is passed in from App.js to let components tell other components that session was just updated
// see the note in App.js for a scenario
export default ({ childProps, setSessionUpdated }) => {
  
  return  (
  <Switch>
      <AppliedRoute path="/" exact component={RootRoute} props={childProps} />

      <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
      <UnauthenticatedRoute path="/forgot-password" exact component={ForgotPassword} props={childProps} />
      
      <AuthenticatedRoute path="/home" exact component={Home} props={childProps} bypassActiveSeasonCheck={true}/>
      <AuthenticatedRoute path="/games" exact component={GamesMain} props={childProps} accessLevel="admin"/>
      <AuthenticatedRoute path="/games/manage" exact component={ManageGames} props={childProps} accessLevel="admin" bypassActiveSeasonCheck={true}/>
      <AuthenticatedRoute path="/games/winners" exact component={SetWinners} props={childProps} accessLevel="admin" />
      <AuthenticatedRoute path="/commish-notes/manage/:postId?" exact component={ManagePost} props={childProps} accessLevel="admin" bypassActiveSeasonCheck={true}/>
      <AuthenticatedRoute path="/picks" exact component={PicksMain} props={childProps} />
      <AuthenticatedRoute path="/picks/select" exact component={PickGames} props={childProps} />
      <AuthenticatedRoute path="/picks/on-behalf-of" exact component={OnBehalf} props={childProps} />
      <AuthenticatedRoute path="/picks/history/:weekId?" exact component={PicksHistory} props={childProps} />
      <AuthenticatedRoute path="/picks/status" exact component={PicksStatusReport} props={childProps} />
      <AuthenticatedRoute path="/season/manage" exact component={ManageSeason} props={childProps} setSessionUpdated={setSessionUpdated} bypassActiveSeasonCheck={true} />
      <AuthenticatedRoute path="/quiz" exact component={QuizMain} props={childProps} accessLevel="admin" />
      <AuthenticatedRoute path="/quiz/manage" exact component={ManageQuiz} props={childProps} accessLevel="admin" bypassActiveSeasonCheck={true}/>
      <AuthenticatedRoute path="/quiz/picks/select" exact component={PickQuiz} props={childProps} />
      <AuthenticatedRoute path="/quiz/picks/report" exact component={QuizPicksReport} props={childProps} />
      <AuthenticatedRoute path="/standings" exact component={Standings} props={childProps} />

      <AuthenticatedRoute path="/players" exact component={PlayerList} props={childProps} />

      { /* Finally, catch all unmatched routes */ }
      <AppliedRoute component={NotFound} />
    </Switch>
  )
}
  
