import React, {useEffect} from "react";
import { Route, Redirect } from "react-router-dom";
import sessionManager from '../../lib/session'
import ReactGA from 'react-ga'

const pageViewTracking = (pathName) => {
  ReactGA.pageview(pathName)
}

function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export default function UnauthenticatedRoute({ component: C, appProps, ...rest }) {
  const redirect = querystring("redirect");
  const session = sessionManager.getSession()
 
  useEffect(() => {
    pageViewTracking(rest.path)
  },[rest.path])

  return (
    <Route
      {...rest}
      render={props =>
        session.accessToken === null
          ? 
            <>
              <main>
              <section className="page page-slim">
                <C {...props} {...appProps} />
                </section>
              </main>
            </>
          
          : <Redirect
              to={redirect === "" || redirect === null ? "/" : redirect}
            />}
    />
  );
}
