import React from 'react'

const BrowserVersion = () => {
  return (
    <div className="browser-version">
      <div>Your browser information:</div>
      <small>{window.navigator.appVersion}</small>
    </div>
  )

}

export default BrowserVersion