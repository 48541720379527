import React from "react"
import './Alert.scss'
import classnames from 'classnames'
import { getISODay } from "date-fns";

const Alert = ({id,type,message,heading = null,context}) => {
  
  const cssClass = () => {
    let value = 'alert'
    if(type === 'error') {
      value += ' alert-danger'
    } else if(type === 'success') {
      value += ' alert-success'
    } else if(type === 'warning') {
      value += ' alert-warning'
    }

    if(context === 'inline') value += ' inline'
    return value
  }
    
  const getId = () => {
    if(id) {
      return id
    } else {
      return "generic-alert"
    }
  }
  return (
      typeof(message) !== 'undefined' && message !== '' && 
      <div id={getId()} className={cssClass()} role="alert">       
        <i className={classnames(
              'fas',
              { 'fa-exclamation-triangle': type === 'error' || type === 'warning' },
              { 'fa-check-square': type === 'success' }
          )}></i>
          <div>
         {
          heading && 
          <label className='heading'>{heading}</label>
         }
         
        {
          Array.isArray(message) &&
          message.map((thisMessage,ctr) => 
            <div className='message' key={`errormsg${ctr}`}>{thisMessage}</div>  
          )
        }

        {
          !Array.isArray(message) &&
          <div className='message'>{message}</div>
        }
  
         
         </div>
      </div> 
  )
    
};

export default Alert