import React,{useState, useEffect, useReducer, useCallback} from 'react'
import sessionManager from '../lib/session'
import {get, post} from '../lib/call-api'
import GameList from './components/GameList'
import WeekList from '../common/components/WeekList'
import SubmitButton from '../common/components/SubmitButton'
import Alert from '../common/components/Alert'
import './css/common.scss'
import useIsMountedRef from '../common/hooks/useIsMountedRef.js'

import {initialState,gamesReducer} from './gamesReducer.js'

const ManageGames = () => {
  const [savedGames,setSavedGames] = useState([])
  const sessionData = sessionManager.getSession()

  const [selectedWeek,setSelectedWeek] = useState(sessionData.thisWeek)
  const [pageLevelError,setPageLevelError] = useState('')
  const [isLoading,setIsLoading] = useState()

  const isMounted = useIsMountedRef()

  const [state, dispatch] = useReducer(gamesReducer, initialState);


  useEffect(() => {
    if(selectedWeek) {
    
      const onGet = (response) => {
        if(isMounted.current === true) {
          setIsLoading(false)
          dispatch({
            "type": "INITALIZE_STATE",
            "games": response.games
          })
        }
      }

      const onGetError = (response) => {
        if(isMounted.current === true) {
          setIsLoading(false)
        }
        if(response.success === false) throw new Error('Unable to retrieve existing games due to a system error.')
        return response
      }

      get(`/api/games/list/week/${selectedWeek.weekId}/manage`,onGet,onGetError)
    
    }

    return () => { isMounted.current = false}
  },[selectedWeek,isMounted])
 



const validateForm = () => {
  const stateOfGames = state.games.map(game => validateGame(game))
  const gamesWithErrors = stateOfGames.filter(isValid => isValid === false)
  const countOfTieBreakers = state.games.filter(game => game.isTieBreaker === true)
  
  if(countOfTieBreakers.length === 0) { 
    setPageLevelError('Please select a game to use as the tiebreaker.')
    return false
  } else if(countOfTieBreakers.length > 1) {
    setPageLevelError(`Please only one game to use as the tiebreaker. There are currently ${countOfTieBreakers.length} games selected.`)
    return false
  } else if(gamesWithErrors.length > 0) {
    setPageLevelError('There are errors with one or more games. See below.')
    return false
  } else {
    setPageLevelError('')
    return true
  }
  
}

const validateGame = (gameData) => {
  if(!gameData.spread || isNaN(gameData.spread)) {
    dispatch({
      "type": "UPDATE_GAME_ERROR",
      "gameId": gameData.gameId,
      "errorMessages": [{
        "messageType": "error",
        "message": 'Please provide a spread'
      }]
    })   
    return false     
  } else {
    return true
  }
  
}

const handleSubmit = async (event) => {
  event.preventDefault()
  setIsLoading("submit")

  const onPost = (response) => {
    setIsLoading()
    setPageLevelError('')
    setSavedGames(response.games)
  }
  const onError = (error) => {
    setPageLevelError(error.message)
    setIsLoading()
  }
  const reqBody = {
    "games": state.games
  }
  post('/api/games/save',onPost,onError,setIsLoading,'save your updates',validateForm, reqBody)
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });

}

const setSpread = useCallback((gameId, spread) => {
  dispatch({
    "type": "UPDATE_SPREAD",
    "gameId": gameId,
    "spread": spread
  })
}, [])

const setIsTiebreaker = useCallback((gameId, isTiebreaker) => {
  dispatch({
    "type": "UPDATE_IS_TIEBREAKER",
    "gameId": gameId,
    "isTiebreaker": isTiebreaker
  })
}, [])

const [refreshSuccess,setRefreshSuccess] = useState(null)

useEffect(() => {
  if(refreshSuccess === true) {
    //setTimeout(() => setRefreshSuccess(),2000)
  }
},[refreshSuccess])

const onRefreshedSchedule = (event,response) => {
  event.preventDefault()
  setRefreshSuccess(true)

  dispatch({
    "type": "INITALIZE_STATE",
    "games": response.games
  })

}

const onRefreshedScheduleErrror = (error) => {
  setPageLevelError(error.message)
  setIsLoading()
}


  return (
    <>
      {
        savedGames && savedGames.length > 0 && 
        <section>
          <h1>Schedule: Week {selectedWeek.weekNum}</h1>
          <Alert type='success' message={`The schedule for week ${selectedWeek.weekNum} is set!`} />
          <GameList games={savedGames} userAction='manage' />
        </section>
      }
      
      {
        savedGames && savedGames.length === 0 &&
        <form onSubmit={(event) => handleSubmit(event)}>
          <div className='page-utils'>
          {
            (refreshSuccess === false || refreshSuccess === null) &&
            <RefreshGames onRefresh={onRefreshedSchedule} onRefreshError={onRefreshedScheduleErrror} onClick={() => {setIsLoading('refresh')}}/>
          }
            {
            refreshSuccess === true &&
              <Alert type="success" message="Schedule refreshed" context="inline" />
            }
          </div>

          <h1>Manage Games</h1>

          <Alert type='error' message={pageLevelError} />
          
          <section className="week-list-container">
            <label>Select a week:</label> <WeekList seasonId={sessionData.season.seasonId} handleChange={(weekData) => { setSelectedWeek(() => weekData) }} selectedWeek={selectedWeek} />
            <p>Note: if you change the week you will lose any unsaved changes below.</p>
          </section>

          {
            state.games && 
            <>
            <GameList games={state.games} userAction='manage' onSpreadChange={setSpread} onIsTiebreakerChange={setIsTiebreaker} />

            <div className='button-container pinned'>
            <div className='form-status'>
                Spreads entered for {state.games.filter(game => game.spread !== null).length} of {state.games.length}  games
              </div>

              <div>
                <SubmitButton isLoading={isLoading === "submit"} />  
                </div>
            </div>
            </>
          }
          
        </form>
      }
    </>
  )
}

const RefreshGames = ({onRefresh,onRefreshError,onClick}) => {
  const sessionData = sessionManager.getSession()
  const [isRefreshing,setIsRefreshing] = useState(false)
  const refreshGameData = (event) => {
    event.preventDefault()
    onClick()
    setIsRefreshing(true)
    
    
    const onPost = (response) => {
      onRefresh(event,response)
    }
    
    const onError = (error) => {
      onRefreshError(error)
    }
    
    const reqBody = {
      "weekId": sessionData.thisWeek.weekId
    }
    
    post('/api/schedule/refresh',onPost,onError,setIsRefreshing,'refreshing the schedule',null,reqBody)
  
  } 

  return (
    <button className="btn btn-icon" onClick={(event) => refreshGameData(event)}>
      <i className={(isRefreshing === "true") ? "fas fa-sync-alt fa-spin" : "fas fa-sync-alt"} ></i>
      Refresh schedule
    </button>  
  )
}

export default ManageGames