import React from 'react'

const DisplayQuestion = ({question,ctr,onAnswerSelect,onEdit,onDelete,selectedAnswerId,totalQuestions}) => {
  const context = (!onEdit && !onDelete && onAnswerSelect) ? 'selecting' : 'read-only'
  
  const getTextPorints = (points) => {
    const textPoints = ['zero','one','two','three']
    if(textPoints[points]) {
      return textPoints[points]
    } else {
      return points
    }
  }

  const userSelectedAnswer = (userSelectionId,answerId) => {
    
    return userSelectionId === answerId 
  }

  const getAnswerClass = (userSelectionId,answer) => {
   if(onAnswerSelect) {
    if(userSelectionId === answer.answerId) {
      return 'quiz-selection selected'
    } else {
      return 'quiz-selection'
    }
      
  } else {
      if(userSelectedAnswer(userSelectionId,answer.answerId) && answer.isCorrect === true) {
        return 'quiz-selection selected correct'
      } else if(userSelectedAnswer(userSelectionId,answer.answerId) && answer.isCorrect === false) {
        return 'quiz-selection selected incorrect'
      } else if(answer.isCorrect === true) {
        return 'quiz-selection correct'
      } else {
        return 'quiz-selection'
      }
    }
  }

  const handleAnswerClick = (questionId,answerId) => {
  
    if(onAnswerSelect) {
      //setSelectedAnswerId(answerId)
      onAnswerSelect(questionId,answerId)
    }
  }

  const pointOrPoints = (points) => {
    if(points === 1) {
      return "point"
    } else {
      return "points"
    }
  }

return (
   <section className={`quiz-item ${context}`}>
     <div className="quiz-points">
      <div id="questionCount">Question {ctr+1} 
      {
        totalQuestions && !isNaN(totalQuestions) && 
        <> of {totalQuestions}</>
      }
      : <span className="text-points">{getTextPorints(question.points)} {pointOrPoints(question.points)}</span></div>
      {
        (onEdit || onDelete) &&
        <div className='quiz-question-actions'> 
        {
          onEdit &&
          <button className='btn btn-icon btn-edit' onClick={(event) => {onEdit(event,question)}}>Edit</button>
        }
        {
          onDelete &&
          <button className='btn  btn-icon btn-delete' onClick={(event) => onDelete(event,question)} title='Click to delete'>Delete</button>
        }
        </div>
      }
     </div>

    <div className="quiz-question">
      <p id="questionText" className='question-text'>{question.questionText}</p>
        { 
          context === 'selecting' && 
          <>
        
            {
              question.pointsAwarded > 0 && 
              <div id="questionResult" className='quiz-question-result correct'><span>Correct! {question.pointsAwarded} points awarded!</span></div>
            }

            {
              question.pointsAwarded === 0 && 
              <div id="questionResult" className='quiz-question-result incorrect'><span>Incorrect!</span></div>
            }
          </>
        }
    </div>


   
      <div className="quiz-answers">
        {
          question.answers.filter(answer => answer.answerText !== '').map((answer) => 
          <div id={`answer${answer.answerId}`} key={`answer-${answer.answerId}`} className={getAnswerClass(selectedAnswerId,answer)} onClick={() => handleAnswerClick(question.questionId,answer.answerId)} >
            {
              !onAnswerSelect &&
              <AnswerIcon isCorrect={answer.isCorrect} />
            }
            {answer.answerText}
        
            {
              userSelectedAnswer(selectedAnswerId,answer.answerId) === true && context === "read-only" && 
              <span id={`userAnswerIndicator${answer.answerId}`}>(Your answer)</span>
            }
          </div>
          )
        }
      </div>
    </section>
  )

}

const AnswerIcon = ({isCorrect}) => {
  const getIconClass = (isCorrect) => {
      if(isCorrect === true) {
        return 'fas fa-check-circle'
      } else {
        return 'fas fa-times-circle'
      }   
    }
  return (
    <i className={getIconClass(isCorrect)}></i>
  )
}


export default DisplayQuestion