import React from 'react'
import Alert from '../../common/components/Alert'
import TeamIcon from '../../teams/components/TeamIcon'
import DisplaySpread from './DisplaySpread'
import DisplayTime from '../../common/components/DisplayTime'
import '../css/DisplayGame.scss'


const DisplayGame = React.memo(function DisplayGame(props) {
  const {game, userAction, onTeamSelect, onTotalPointsEntry, selectedTeamIdAttribute, onSpreadChange, onIsTiebreakerChange } = props
  // readOnly tells the form if we should display fields or not.
  const readOnly = (game.allowAction === false || (!onTeamSelect && !onTotalPointsEntry && !onSpreadChange && !onIsTiebreakerChange)) ? true : false

  const getClassName = () => {
    let baseClass = 'card'
    if(game.allowAction === false && game.errorMessages.length > 0) {
      baseClass += ' disabled'
    }
    return baseClass
  }

  let warnings = []
  let errors = []
  if(game.errorMessages && game.errorMessages.length > 0) {
    warnings = game.errorMessages.filter(item => item.messageType === "warning") 
    errors = game.errorMessages.filter(item => item.messageType === "error")
  }
  
  return (
    <div className={getClassName()}>   
      { 
        errors.length > 0 &&
        <Alert id={`errorAlert${game.gameId}`} type='error' message={ errors.map(item => item.message )} context='inline'/>
      } 
      { 
        warnings.length > 0 &&
        <Alert id={`warningAlert${game.gameId}`} type='warning' message={ warnings.map(item => item.message ) } context='inline'/>
      } 
      <div className='heading-with-icon'>
        <label className='heading' id={`time${game.gameId}`}><DisplayTime toFormat={game.dateTime} /></label>
      </div>
    
    <div className='game-row'>          
      <div className='team home-team'>
        <label className='heading'>Home</label>
        <TeamIcon id={`homeTeam${game.gameId}`} gameId={game.gameId} teamToDisplay={game.home} handleClick={onTeamSelect} isSelected={game[selectedTeamIdAttribute] === game.home.teamId} actionAllowed={game.allowAction} /></div>
      <div className='spread-container'>
        <label className='heading'>vs</label>
        {
          !onSpreadChange &&
          <DisplaySpread id={`displaySpread${game.gameId}`} spread={game.spread} />
        }
        {
          onSpreadChange &&
          <input id={`editSpread${game.gameId}`} type="text" defaultValue={game.spread || ''} placeholder='Spread' onBlur={(event) => {onSpreadChange(game.gameId, event.target.value)}} className='form-control'/>
        }
      </div>
      <div className='team away-team'>
        <label className='heading'>Away</label>
        <TeamIcon id={`awayTeam${game.gameId}`} gameId={game.gameId} teamToDisplay={game.away} handleClick={onTeamSelect} isSelected={game[selectedTeamIdAttribute] === game.away.teamId} actionAllowed={game.allowAction} /></div>
    </div>

    <Tiebreaker 
      game={game} 
      readOnly={readOnly} 
      onSelectChange={onIsTiebreakerChange} 
      onValueChange={onTotalPointsEntry} 
      userAction={userAction} />

    </div>
  )
})

const Tiebreaker = ({game,readOnly,userAction,onSelectChange,onValueChange}) => {
  return (
    <>
    {
      userAction === 'manage' &&
        <>
        {
          onSelectChange &&
          <div className='card-footer tie-breaker-select'>
          <label className='heading' id={`tiebreakerHeading${game.gameId}`}>Use for tie-breaker?</label>
          <select id={`isTiebreakerSelect${game.gameId}`} onChange={(event) => {onSelectChange(game.gameId,event.target.value)}} value={(game.isTieBreaker === true) ? 1 : 0}>
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
          </div>
        }
        {
          !onSelectChange && game.isTieBreaker === true &&
            <div className='card-footer tie-breaker-select'>
              <span id={`isTiebreaker${game.gameId}`}>This game will be used for the tiebreaker</span>
            </div>
        }
      </>
    } 

    {
      (userAction === 'picks' || userAction === 'winners') && game.isTieBreaker === true &&
      <div className='card-footer tie-breaker-select'>
        <label className='heading' id={`tiebreakerHeading${game.gameId}`}>Tiebreaker. Total points in game.</label>
        {
          (readOnly === true) &&
            <>
            {
              game.totalPointsScored === null && 
              <span id={`totalPointsNotProvided${game.gameId}`} className="no-results">Not provided</span>
            }

            { game.totalPointsScored !== null && 
              <span id={`totalPointsValue${game.gameId}`} className="total-points-scored-value">{game.totalPointsScored}</span>  
            }
          </>
        }

        {
          readOnly === false && game.allowAction &&
            <input 
              id={`inputTiebreaker${game.gameId}`}
              type="text" 
              defaultValue={game.totalPointsScored || ''} 
              onBlur={(event) => onValueChange(game.gameId,event.target.value)}
            />
          
        }
      </div>
    } 
  </>
  )

}

export default DisplayGame