import React from 'react'
import GamePicksSummaryByWeek from './components/GamePicksSummaryByWeek'

const PicksHistory = (props) => {
  const {weekId} = props.match.params
  return (
    <>
    <h1>Your picks this season</h1>
      <GamePicksSummaryByWeek preSelectedWeekId={weekId} />
    </>
  )
}

export default PicksHistory