import React from 'react'
import {Link} from 'react-router-dom'
import DisplayPost from './commishNotes/components/DisplayPost'
import sessionManager from './lib/session'
import SeasonSummary from './standings/components/SeasonSummary'
import WeekSummary from './standings/components/WeekSummary'
import PickStatusSummary from './picks/components/PickStatusSummary'
import AdminStatusReport from './common/components/AdminStatusReport'
const Home = () => {
  const sessionData = sessionManager.getSession()

  return (
   <>
   

    <div className="grid-container col-2-1 with-gutter reverse">
      <div>
      {
        sessionData.pool && sessionData.pool.userIsAdmin === true && 
        <AdminStatusReport />
      }
    

        <div className="callout">
          <header>
            {
                sessionData.pool && sessionData.pool.userIsAdmin === true && 
                <div className="util">
                  <Link to='/commish-notes/manage' className='btn btn-icon btn-add'>Add a new post</Link>
                  </div>
            }
            <h2>Commish Notes</h2>
          </header>
          <DisplayPost />
        </div>
      </div>
      <div>

        {
          (!sessionData || !sessionData.thisWeek.weekId) &&
          <div className="callout">
            <h2>Welcome!</h2>
            {
                sessionData.pool && 
                <>
                {
                  sessionData.pool.userIsAdmin === true && 
                  <>
                    <p className="intro">No season has been set up.</p>
                    <Link to="/season/manage">Set-up a season</Link>
                  </>
                }

              {
                  sessionData.pool.userIsAdmin === false && 
                  <>
                    <p className="intro">No season has been set up by your pool's administrator.</p>
                  </>
                }
                </>
            }
          </div>
        }

        {
          sessionData && sessionData.thisWeek.weekId &&
          <>
           <WeekSummary />
            
            <SeasonSummary />
            
            <PickStatusSummary />
          </>

        }

       
      </div>
    </div>
  </>
  ) 
}


export default Home