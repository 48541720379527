import React from "react"

const Rank = ({rank}) => {
  
  const placeSuffix = (passedRank) => {
    const asStr = passedRank + ''
    if(asStr[asStr.length-1] === '1') {
      return 'st'
    } else if(asStr[asStr.length-1] === '2') {
      return 'nd'
    } else if(asStr[asStr.length-1] === '3') {
      return 'rd'
    } else {
      return 'th'
    }
  } 
  return(
    <div className="rank">
      {rank}{placeSuffix(rank)} 
    </div>
  )
}

export default Rank