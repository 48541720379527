import React, { useState, useEffect } from "react"
import Alert from '../../common/components/Alert'
import SetConfirmationCode from './SetConfirmationCode'
import SetPassword from './SetPassword'
import SubmitButton from '../../common/components/SubmitButton'

const ForgotPasswordVerifyAndSet = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [globalErrorMessage, setGlobalErrorMessage] = useState('')
    
    const [verificationCode, setVerificationCode] = useState('')
    const [newPassword, setNewPassword] = useState('')

    const [endpointPath,setEndPointPath] = useState(`/auth/register/confirm`)
    const [headingText,setHeadingText] = useState('Enter your confirmation code')
    const [buttonActionText,setButtonActionText] = useState('Confirm Your Account')
    useEffect(() => {
        if(props.context === "PASSWORD_RESET") {
            setEndPointPath(`/auth/password/forgot/reset`)
            setHeadingText('Reset Your Password')
            setButtonActionText('Reset Password')
        } else if(props.context === "FORCE_RESET") {
            setEndPointPath(`/auth/password/force-reset`)
            setHeadingText('Reset Your Password')
            setButtonActionText('Reset Password')
        } 

    },[props.context])

    const validateForm = () => {
        let errorMessages = []
        
        
        if(verificationCode === '' && props.context !== 'FORCE_RESET') {
            errorMessages.push("Please provide a verification code.")
        } 
        
        if((props.context === "PASSWORD_RESET" || props.context === 'FORCE_RESET') && newPassword === '') {
            errorMessages.push("Please provide a new password.")
            
        } 
        return errorMessages
    }

    const handleErrors = (response) => {
        if (response.success !== true) {
          throw new Error(response.message);
        }
        return response;
    }

    const handleSubmit = async event => {
        event.preventDefault()
        setIsLoading(true)
        const errors = validateForm()
        if(errors.length === 0) {
            let request = {}
            if(props.context === "PASSWORD_RESET") {
                request = {
                    username: props.values.email,
                    confirmationCode: verificationCode,
                    password: newPassword
                }
            } else if(props.context === "FORCE_RESET") {
                request = {
                    userId: props.values.userId,
                    password: newPassword
                } 
            
            } else {
                request = {
                    username: props.values.email,
                    confirmationCode: verificationCode,
                }
            }
            
            fetch(endpointPath, {
                method: 'post',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(request)
            })
            .then(response => response.json())
            .then(handleErrors)
            .then(() => {
                if(props.nextStep) props.nextStep()
            })
            .catch(error => {
                setGlobalErrorMessage(error.message)
                setIsLoading(false)
            });

        } else {
            setGlobalErrorMessage(errors)
            setIsLoading(false)
        }
    }

   

    return (
        <>
            <h1>{ headingText }</h1>
            <form onSubmit={handleSubmit}>
            
            <Alert type='error' message={globalErrorMessage} />
            
           { 
            props.context !== "FORCE_RESET" &&
            <SetConfirmationCode 
                    nextStep={props.nextStep}
                    email={props.values.email} 
                    setValue={setVerificationCode} />
            }
            {
                (props.context === "PASSWORD_RESET" || props.context === "FORCE_RESET") &&
                <SetPassword setValue={setNewPassword} context={props.context}/>
            }
            
            <div className="buttonContainer">
                <SubmitButton actionTextOverride={buttonActionText} isLoading={isLoading} />  
            </div>

        </form>
       </>
    )
    
}

export default ForgotPasswordVerifyAndSet