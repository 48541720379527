import React from 'react'

const SubmitButton = ({actionTextOverride,isLoading}) => {
  const actionText = (actionTextOverride && actionTextOverride !== 'undefined' && actionTextOverride !== '') ? actionTextOverride : 'Save'
  return (
    <button type="submit" className="btn btn-primary">
      {isLoading === true && (
        <i className="fas fa-spinner fa-pulse"></i>
      )}
      {actionText}
  </button>
  )
}
export default SubmitButton