import sessionManager from './session'
const apiPath = process.env.REACT_APP_API_URI || ""

const handleErrors = async (response) => {
  const responseBody = await response.json()
  
  if(response.status === 401) {
    throw new Error(401)
  } else if(response.status === 403) {
    sessionManager.clearSession()
  } else if(response.ok === false) {
    throw new Error(responseBody.message)
  } else {
    return responseBody
  }
}

const authHeader = () => {
  const accessToken = sessionManager.getToken()
  return (accessToken) ? { 'authorization': `BEARER ${accessToken}` } : {}
}

export const get = (path,onSuccess,onError) => {
  fetch(`${apiPath}${path}`, {
    "method": 'get',
    "headers": authHeader()
  })
  .then(response => handleErrors(response))
  .then(resp => {
    onSuccess(resp)
  })
  .catch(error => {
    if(onError) onError(error.message)
  })
}

export const post = (path,onSuccess,onError,setIsLoading,pageDescription,validateFunction,body) => {
  if ((validateFunction === null || typeof(validateFunction) === "undefined") || validateFunction() === true) {
    const localAuthHeader = authHeader()
    fetch(`${apiPath}${path}`, {
      "method": "post",
      "headers": {
        "Accept": "application/json",
        "Content-Type": "application/json",
        ...localAuthHeader
      },
      "body": JSON.stringify(body)
    }).then(response => 
      handleErrors(response, pageDescription)
    ).then(response => {
      onSuccess(response)
      if(setIsLoading) setIsLoading(false)
    })
      .catch(error => {
        onError(error.message)
        if(setIsLoading) setIsLoading(false)
      })
  } else {
    setIsLoading(false)
  }
}

export const httpDelete = (path,onSuccess,onError,setIsLoading,pageDescription,body) => {
  
  const localAuthHeader = authHeader()
  fetch(`${apiPath}${path}`, {
    method: 'delete',
    "headers": {
      "Accept": "application/json",
      "Content-Type": "application/json",
      ...localAuthHeader
    },
    "body": (body) ? JSON.stringify(body) : null
  }).then(response => 
    handleErrors(response, pageDescription)
  ).then(response => {
    onSuccess(response)
    if(setIsLoading) setIsLoading(false)
  })
    .catch(error => {
      onError(error.message)
      if(setIsLoading) setIsLoading(false)
    })
  
}