export const initialState = {
  "games": null
}

const updateState = (games,gameId,attribute,value) => {
  return games.map(game => {
    if(game.gameId === gameId) {
      return {
        ...game,
        [attribute]: value
      }
    } else {
      return game
    }
  })
}

export const gamesReducer = (state, action) => {
  switch (action.type) {
    
    case "INITALIZE_STATE":
      return {
        "games": action.games
      }
    case "UPDATE_WINNING_TEAM":
      return {
        "games": updateState(state.games,action.gameId,"winningTeamId",action.winningTeamId)
      }

    case "UPDATE_TOTAL_POINTS":
      return {
        "games": updateState(state.games,action.gameId,"totalPointsScored",action.totalPointsScored)
      }
      case "UPDATE_SPREAD":
        return {
          "games": updateState(state.games,action.gameId,"spread",action.spread)
        }
      case "UPDATE_IS_TIEBREAKER":
        return {
          "games": updateState(state.games,action.gameId,"isTieBreaker",parseInt(action.isTiebreaker) === 1)
        }
      case "UPDATE_PICK":
        return {
          "games": updateState(state.games,action.gameId,"selectionId",action.selectionId)
        }

      case "UPDATE_GAME_ERROR":
        return {
          "games": updateState(state.games,action.gameId,"errorMessages",action.errorMessages)
        }
    default:
      throw new Error();
  }
}