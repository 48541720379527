
const utils = { 
  /*
    provides functionality similar to mySql's INSERT ON DUPLICATE KEY UPDATE

    if an object (item) already exists in an array (allItems) based on a given attribute (attrib)
    we'll update the data for that item.

    if it doesn't exist we'll add it
  */
  saveArray: (item,attrib,allItems) => {
    const index = allItems.findIndex(existingItem => existingItem[attrib] === item[attrib])
    if(allItems.length === 0) {
      return ([item])
  } else  if(index === -1) {
      /* add new item */
      return (
        prevItems => 
        [
          ...prevItems,
          item
        ]
      )
    } else {
      /* update existing item */
      
      return allItems.map(existingItem => {
        if(existingItem[attrib] === item[attrib]) {
          return item
        } else {
          return existingItem
        }
      })
      
    }
  },

  removeFromArray: (item,attrib,allItems) => {
    return allItems.filter(existingItem => existingItem[attrib] !== item[attrib])
  },

  getUniqueList: (attr,list) => {
    if(attr !== null && attr !== '') {
      return [...new Set(list.map(item => item[attr]))]
    } else {
      return [...new Set(list.map(item => item))]
    }
  },
  createTempId: () => {
    const randomNumber = Math.floor(Math.random() * 2453453451616)
    return `temp${randomNumber}`
  },

  getUserName: (userData) => {
    return (userData.nickname !== null) ? (userData.nickname) : `${userData.firstName} ${userData.lastName}`
  },

  outputSeparator : (list,currentIndex) => {
    if(list.length == 2 && currentIndex === 0) {
      return ' & '
    } else if(list.length > 2 && currentIndex !== list.length -1) {
      if(currentIndex < list.length - 2) {
        return ', '    
      } else {
        return ', and '
      }
    }
  }
}

export default utils