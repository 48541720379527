import React, {useState,useEffect} from 'react'
import {get} from '../../lib/call-api'
import useIsMountedRef from '../hooks/useIsMountedRef'

const WeekList = ({seasonId, handleChange, selectedWeek, allowAll}) => {
  const [weeks,setWeeks] = useState(null)
  const isMounted = useIsMountedRef()

  useEffect(() => {
    const onGet = (response) => {
      if(isMounted.current === true) {
        setWeeks(response.list)
      }
    }
    get(`/api/schedule/week/list/${seasonId}`,onGet,null)
    return () => { isMounted.current = false}
  }, [seasonId,isMounted])

  
  const onSelect = (event) => {
    if(event.target.value === 'all') {
      handleChange(event.target.value)
    } else if(event.target.value !== '') {
      const selectedWeekId = parseInt(event.target.value)
      handleChange(weeks.filter(week => week.weekId === selectedWeekId+"")[0])
    }
    
  }

  return (
    <select onChange={(event) => onSelect(event)} value={selectedWeek.weekId}>
      
      {
        weeks && weeks.length > 0 && 
        <>
        {
          allowAll === true && 
          <option value='all'>All</option>
        }

      {
          (!allowAll || allowAll === false) && 
          <option value=''></option>
        }

        {
          weeks.map(week => 
            <option value={week.weekId} key={`week-item-${week.weekId}`}>Week {week.weekNum}</option>  
          )
        }
        </>
      }      
    </select>
  )

}

export default WeekList