import React, { createContext, useContext,useEffect } from 'react'

const ModalContext = createContext()

const modalReducer = (state, action) => {
  switch (action.type) {
        case "toggleIsOpen" :
            return {isOpen: state.isOpen === false }
      
        default :
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

const ModalProvider = ({children}) => {
    
    let [ modalState, modalDispatch ] = React.useReducer(
        modalReducer, 
        {
            isOpen: false
        });
    let modalValue = [ modalState, modalDispatch ];

    useEffect(() => {
      const containerId = 'modal-container'
      
      const element = document.getElementById(containerId);
      const className = 'active'
  
      // doesn't work in IE9. I don't think I care.
      if(modalState.isOpen === true) {
        element.classList.add(className);
      } else {
        
        element.classList.remove(className);
      }
  
    },[modalState.isOpen])

    return (
        <ModalContext.Provider value={modalValue}>
            { children }
        </ModalContext.Provider>
    )
}

const useModalState = () => {
    const context = useContext(ModalContext)
    if (context === undefined) {
      throw new Error('useModalState must be used within a ModalProvider')
    }
    return context
}


export { useModalState , ModalProvider}