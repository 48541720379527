import React from 'react'
import { Link } from "react-router-dom"
import utils from '../../lib/util'
import './User.scss'

const User = ({userData,handleClick,showArrow}) => {
  return (
    <div className='user'>
      {
        handleClick &&
        <Link to="#" onClick={() => { handleClick()}}>
          <BaseOutput userData={userData} showArrow={showArrow} />
        </Link>
      }
      {
        !handleClick && 
          <BaseOutput userData={userData} showArrow={showArrow}/>
      }
    </div>
  )
}

const BaseOutput = ({userData, showArrow}) => {

  return (
    <>
     { utils.getUserName(userData) }
      {
        showArrow === true &&
        <span className='icon expand-arrow'></span>
      }
    </>
  )
}

export default User