const cleanHtml = (notesDom) => {
  return processChildren(notesDom)
}

const cleanStyles = (element) => {
  //element.style = "" // get rid of styles from MS word or Google docs
  let newStyles = ''
  for(let i=0;i<element.style.length;i++) {
    const thisStyle = element.style[i]
    const thisValue = element.style.getPropertyValue(thisStyle)
    if( 
      thisStyle !== 'line-height' &&
      thisStyle !== 'margin-top' && 
      thisStyle !== 'margin-bottom' &&
      thisStyle !== 'margin' &&
      thisStyle !== 'white-space' &&
      thisValue !== '' &&
      thisValue !== null

    ) {
       newStyles = newStyles + `${thisStyle}: ${thisValue};`
    }
  }
  return newStyles
 
}

const processChildren = (htmlString) => {
  const parentAsHtml = document.createElement('div')
  parentAsHtml.innerHTML = htmlString
  const children = parentAsHtml.children;
  
  for (var i = 0; i < children.length; i++) {
    let child = children[i]
    var regEx = /\S/g
    if(regEx.test(child.textContent) === false) {
      
      child.remove()
    }
  } 

  for (var i = 0; i < parentAsHtml.children.length; i++) {
    parentAsHtml.children[i].style.cssText = cleanStyles(parentAsHtml.children[i])
      if(parentAsHtml.children[i].children.length > 0) processChildren(parentAsHtml.children[i])
  } 

  return parentAsHtml

}

module.exports = {cleanHtml}