import React, {useEffect} from "react";
import { Route } from "react-router-dom";
import ReactGA from 'react-ga'

const pageViewTracking = (pathName) => {
  ReactGA.pageview(pathName)
}

export default ({ component: C, props: cProps, ...rest }) => {
  
  useEffect(() => {
    pageViewTracking(rest.path)
  },[rest.path])

  return (
    <Route {...rest} render={props => <C {...props} {...cProps} />} />
  )
}