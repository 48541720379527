import React from 'react'
import {Link} from 'react-router-dom'
import sessionManager from '../lib/session'


const GamesMain = () => {
  const sessionData = sessionManager.getSession()
    return (
    <>
    <h1>Games</h1>

    <ul>
      {
        sessionData.pool && sessionData.pool.userIsAdmin === true && 
        <>
        <li>
          <Link className="nav-link" to="/games/manage">Enter spreads</Link>
        </li>
        <li>
          <Link className="nav-link" to="/games/winners">Enter winners</Link>
        </li>
        </>
      }
    </ul>
   
    </>
  )
}

export default GamesMain