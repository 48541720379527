import React, { useEffect,useState } from 'react'
import {Link} from 'react-router-dom'
import {get} from '../../lib/call-api'
import utils from '../../lib/util'
import Rank from '../../common/components/Rank.js'
import '../css/styles.scss'
import useIsMountedRef from '../../common/hooks/useIsMountedRef'

const SeasonSummary = () => {

  const [currentUserRankDetails,setCurrentUserRankDetails] = useState(null)
  const [firstPlace,setFirstPlace] = useState(null)
  const [isLoading,setIsLoading] = useState(true)
  const [message,setMessage] = useState()
  const [messageType,setMessageType] = useState()
  const [tiedWith,setTiedWith] = useState(null)
  const [haveResultsForSeason,setHaveResultsForSeason] = useState(false)

  const isMounted = useIsMountedRef()

  useEffect(() => {
    const onGet = (response) => {
      if(isMounted.current === true) {
        setMessage()
        setMessageType()
        setIsLoading(false)

        const currentUser = response.currentUser
        const firstPlaceResp = response.firstPlace
        
        setCurrentUserRankDetails(currentUser)
        setFirstPlace(firstPlaceResp)
        setTiedWith(response.currentUserTiedWith)

        const firstPlaceWithNoneCorrect = firstPlaceResp.filter(user => user.games.summary.totalCorrect === 0)

        // if everyone in "first place" had no picks correct, we probably don't have any results yet
        if(firstPlaceResp.length > 0 && firstPlaceResp.length === firstPlaceWithNoneCorrect.length) {
          setHaveResultsForSeason(false)
        } else {
          setHaveResultsForSeason(true)
        }
      }

    }
  
    const onGetError = (error) => {
      if(isMounted.current === true) {
        setIsLoading(false)
        setMessage(`Couldn't get a summary of last week`)
        setMessageType('error')
      }
    }

    get(`/api/standings/summary/season`,onGet,onGetError)
    
    return () => { isMounted.current = false }
  },[isMounted])

  return (
  
    <div className="callout">
      <h3>Standings so far...</h3>
      {
       isLoading === false && haveResultsForSeason === false && 
        <p className="no-results">No results yet for the season.</p>
      }

      {
       isLoading === false && haveResultsForSeason === true && 
       <>

        {
          firstPlace && 
          <OutputPlace data={firstPlace} currentUser={currentUserRankDetails} />
        }
        
        {
          currentUserRankDetails && currentUserRankDetails.games.summary.rank !== 1 &&
          <OutputPlace data={[currentUserRankDetails]} tiedWith={tiedWith} />
        }

        <Link className='btn btn-link' to='/standings'>See the standings</Link>
      </>
      }
    </div>
  )
}

const OutputPlace = ({data,tiedWith}) => {
  
  const rank = data[0].games.summary.rank
  const gamesBehind = data[0].games.summary.gamesBehind

  return (
   
    <div className="standings-summary-row">
      <Rank rank={rank} />
      <div>
      {
        data.length === 1 && 
        <div className="rank-user-name displaying-1"> 
        {
          data[0].nickname
        }
        </div>
      }

      {
        data.length > 1 &&  
        <div className={`rank-user-name displaying-${data.length}`}> 
        {
          data.map((user,userCtr) => 
            <span key={`user-place-${user.nickname}`}>{user.nickname}{utils.outputSeparator(data,userCtr)}</span>
          )
        }
        </div>
      }
      
      { 
          gamesBehind !== 0 &&
          <div className='games-behind'>
            {Math.abs(gamesBehind)} games behind
          </div>
      }
      <TiedWith list={tiedWith} />
      

      
      </div>
    </div>
  )
}

const TiedWith = ({list}) => {
return(
  <>
  {
    list && list.length > 0 &&

    <div className='tied-with'> 
      Tied with 
      
      {
        list.length > 2 &&
        <> {list.length} others</>
      }
      
      {
        list.length <= 2 && 
        list.map((user,userCtr) => 
          <span key={`tied-with-${user.nickname}`}> {user.nickname}{utils.outputSeparator(list,userCtr)}</span>
        )
      }
    </div>
  }
  </>
)
}

export default SeasonSummary