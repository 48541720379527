import React from "react"
import {Link} from "react-router-dom"
import './BarChart.scss'

const BarChart = ({data}) => {
  const uniqueTotalGames = [...new Set(data.map(item => item["totalGames"]))]
  const maxValue = Math.max(...uniqueTotalGames)
  
  return (
    <div className="bar-chart">
      {
        data.map(d => 
          <BarGroup key={`week${d.weekNum}result`} d={d} maxValue={maxValue} />
        ) 
      }
    </div>
  )
}


const BarGroup = (props) => {
  const label = `Week ${props.d.weekNum}`
  let value = props.d.picksCorrect
  const valueWidth = (value) ? value : 0

  let barWidth = (valueWidth / props.maxValue) * 100
  let barWidthPct = barWidth + "%"

  const linkToUrl = (weekData) => {
  if(weekData.picksCorrect !== null) {
    return `/picks/history/${weekData.weekId}`
  } else {
    return null
  }
}

return (
  <div className="bar-group">
    <label className="name-label">
      {
        !value &&
        <>{label}</>
      }
      {
        value &&
        <Link to={linkToUrl(props.d)}>{label}</Link>
      }
    </label>
    <div className="bar-container">
    {
      value !== null &&
      <div className="bar" style={{width: barWidthPct}}>
        {value}
      </div>
    
    }
    {
      value === null &&
      <div className="bar no-results">
        No results
      </div>
    }
    </div>
  </div>
)
}

export default BarChart