import React, {useEffect,useState} from 'react'
import {Link} from 'react-router-dom'
import sessionManager from '../../lib/session'
import {format} from 'date-fns'
import {get} from '../../lib/call-api'
import '../CommishNotes.scss'
import useIsMountedRef from '../../common/hooks/useIsMountedRef'

const DisplayPost = ({passedPostData,editAction}) => {
  const sessionData = sessionManager.getSession()


  const [postId,setPostId] = useState((passedPostData) ? passedPostData.id : null)
  const [postText,setPostText] = useState((passedPostData) ? passedPostData.postText : null)
  const [postDateAdded,setPostDateAdded] = useState((passedPostData) ? passedPostData.dateAdded : null)
  const [postDateLastModified,setPostDateLastModified] = useState((passedPostData) ? passedPostData.dateLastModified : null)
  const [isLoading,setIsLoading] = useState(true)
  const postDate = (postDateLastModified) ? postDateLastModified : postDateAdded

  const isMounted = useIsMountedRef()
  
  useEffect(() => {
    const onGet = (response) => {
      if(isMounted.current === true) {
        setIsLoading(false)
        setPostId(response.post.id)
        setPostText(response.post.postText)
        setPostDateAdded(response.post.dateAdded)
        setPostDateLastModified(response.post.dateLastModified)
      }
    }
    const onError = (error) => {
      console.log(error)
    }
    get(`/api/commish-notes/most-recent`,onGet,onError)
    return () => { isMounted.current = false}
  },[isMounted])
  
    
  return (
    <div className='commish-note'>
      {
        isLoading === false && postText && postDate && 
        <>

        <small>Posted {format(new Date(postDate),'eeee, MMMM do yyyy, h:mm:ss aa')}</small>
        {
          editAction && sessionData.pool && sessionData.pool.userIsAdmin === true && 
          <div><a className="btn btn-icon btn-edit" href="javascript:void(0)" onClick={() => editAction()}>Edit this post</a></div>
        }
        {
          !editAction && sessionData.pool && sessionData.pool.userIsAdmin === true && 
          <div><Link className="btn btn-icon btn-edit" to={`/commish-notes/manage/${postId}`}>Edit this post</Link></div>
        }
        <OutputPost rawText={postText} />
       </>
      }

      {
        isLoading === false && !postText && !postDate && 
        <p className="no-results">No Commish Notes posted yet.</p>        
      }


    </div>
  )
}

const OutputPost = ({rawText}) => {
  const displayText = decodeURIComponent(rawText)
  return (
    <div
      dangerouslySetInnerHTML={{
      __html: displayText
    }}></div>
  )
}
export default DisplayPost