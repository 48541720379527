import React, { useEffect,useState,useRef } from 'react'
import { createPortal } from 'react-dom'
import usePortal from './UsePortal'

import { useModalState  } from './Modal.Context'
import './Modal.scss'

const Modal = (props) => {
  const [ modalState, modalDispatch ] = useModalState()
  const [modalIsPositioned, setModalIsPositioned] = useState(false)
  const containerId = 'modal-container'

  const toggle = () => {
    modalDispatch({type: 'toggleIsOpen', payload: null})
  }
  
  const modalRef = useRef(null)

  const target = usePortal(containerId);


  useEffect(() => {
    if(modalState.isOpen === true && modalIsPositioned === false) {
      const modalHeight = modalRef.current.clientHeight
      const modalWidth = modalRef.current.clientWidth

      const viewportHeight = window.innerHeight
      const viewportWidth = window.innerWidth

      let modalTop = 25
      if(modalHeight < viewportHeight) modalTop = (viewportHeight - modalHeight)/2;

      let modalLeft = (viewportWidth - modalWidth)/2;

      modalRef.current.style.left = `${modalLeft}px`
      modalRef.current.style.top = `${modalTop}px`
      setModalIsPositioned(true)
    } else {
      setModalIsPositioned(false)
    }
  },[modalState.isOpen])


  const onClose = (evt) => {
    if(props.onClose) {
      props.onClose(evt)
    } else {
      toggle()
    }
  }

  return createPortal(<>
    {
      modalState.isOpen === true &&
      <div id="modal" ref={modalRef}> 
        <header>
        {
            (!props.hideClose || props.hideClose === false) && 
            <div className="util">
              <button className="btn btn-link btn-slim" onClick={(event) => onClose(event) }>
                <i className="fas fa-times-circle"></i> 
                Close
              </button>
            </div>
          }
          {
            props.heading &&
            <h2>{props.heading}</h2>
          }
          
          </header>

        { props.children }
       
      </div>  
    }
     

  </>, target)
}



export default Modal