import React,{useState} from 'react'
import SelectPicks from './components/SelectPicks'
import sessionManager from '../lib/session'

const PickGames = () => {

  const sessionData = sessionManager.getSession()
  
  const whosPicks = (capitalize) => {
    if(capitalize === true) {
      return 'Your'
    } else {
      return 'your'
    }
  }

  return (
    <>
    {
      sessionData && 
      <>
        <h1>Make your week {sessionData.thisWeek.weekNum} picks</h1>

        {
          sessionData && 
          <SelectPicks userMakingPicks={sessionData.userData} thisWeek={sessionData.thisWeek} whosPicks={whosPicks} />
        }
      </>
    }
    </>
  )

  }




export default PickGames