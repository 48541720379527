import React from 'react'
import QuizUserSummary from './components/QuizUserSummary'

const QuizPicksReport = () => {
  return (
    <>
    <h1>Your picks this season</h1>
    <QuizUserSummary  />
    </>
  )
}


export default QuizPicksReport