import React,{useEffect,useState} from 'react'
import {Link} from 'react-router-dom'
import {get} from '../../lib/call-api'
import sessionManager from '../../lib/session'
import useIsMountedRef from '../../common/hooks/useIsMountedRef'

const PickStatusSummary = () => {
  const sessionData = sessionManager.getSession()
  const [numberOfGames,setNumberOfGames] = useState(null)
  const [numberOfPicks,setNumberOfPicks] = useState(null)
  const isMounted = useIsMountedRef()

  useEffect(() => {
    const onGet = (response) => {
      if(isMounted.current === true) {
        if(response.summary.length === 0) {
          setNumberOfGames(0)
          setNumberOfPicks(0)
        } else {
          setNumberOfGames(response.summary[0].numberOfGames)
          setNumberOfPicks(response.summary[0].numberOfPicks)
        }
      }
      
    }
    
    const onError = (error) => {
      console.log(error)
    }

    if(sessionData.thisWeek && sessionData.thisWeek.weekId) {
      get(`/api/picks/status/game/week/${sessionData.thisWeek.weekId}/user/${sessionData.userData.userId}`, onGet, onError)
    } 

    return () => { isMounted.current = false}

  },[isMounted])

  return (
    <div className="callout" id="pick-status-summary">
        <h2>Make your picks</h2>
        {
          numberOfGames !== null && numberOfPicks !== null &&
          <>
            {  
              numberOfGames === 0 &&
              <div id="game-picks-no-games">
                <div>Sit tight...no spreads entered yet this week.</div>
              </div>
            }

            {
              numberOfGames > 0 && 
              <>
                {
                  numberOfGames === numberOfPicks && numberOfPicks !== 0 &&
                  <div id="game-picks-made">
                    <div>All set! You've made your picks for week {sessionData.thisWeek.weekNum}</div>
                    <Link to="/picks/select">Review your picks</Link>
                  </div>
                }
                { 
                  numberOfGames > numberOfPicks && numberOfPicks !== 0 &&
                  <div id="game-picks-partially-made">
                    <div>Finish the job. You've made {numberOfPicks} of {numberOfGames} picks.</div>
                    <Link to="/picks/select">Finish making your picks</Link>
                  </div>
                }
                { 
                  numberOfGames > 0 && numberOfPicks == 0 &&
                  <div id="game-picks-not-made">
                    <div>You have not made your picks for week {sessionData.thisWeek.weekNum}.</div>
                    <Link to="/picks/select">Make your picks</Link>
                  </div>
                }

              </> 
            }
          </>
        }
    </div>
  )
}

export default PickStatusSummary