import React, {useState,useEffect} from 'react'
import {Link} from 'react-router-dom'
import PickStatusSummary from './components/PickStatusSummary'
import UserSummary from '../standings/components/UserSummary'
import sessionManager from '../lib/session'
import { get } from '../lib/call-api'
import useIsMountedRef from '../common/hooks/useIsMountedRef'
import BarChart from '../common/components/BarChart'

const PicksMain = () => {
    const sessionData = sessionManager.getSession()
    const isAdmin = sessionData.pool && sessionData.pool.userIsAdmin === true
   
    return (
    <>
    {
      isAdmin === true &&
        <h1>Games &amp; Picks</h1>
    }
    {
      isAdmin === false &&
        <h1>Picks</h1>
    }
      {
        isAdmin === true &&
        <AdminOnlyContent />
      }

      <div className="grid-container col-1-1">
        
        <PickStatusSummary />

        <UserSummary />

      </div>
      
      <SeasonSummaryByWeek />
    
    </>
  )
}

const AdminOnlyContent = () => {
  return (
    <div className="callout prominent">
      <h2>Admin only</h2>
      <div className="grid-container col-1-1-1">
        <div>
          <h3>Picks</h3>
          <ul>
            <li><Link className="nav-link" to="/picks/on-behalf-of">Pick on behalf of...</Link></li>
            <li><Link className="nav-link" to="/picks/status">Status report</Link></li>
          </ul>
        </div>
          
        <div>
          <h3>Games</h3>
          <ul>
            <li><Link className="nav-link" to="/games/manage">Enter spreads</Link></li>
            <li><Link className="nav-link" to="/games/winners">Enter the winning teams</Link></li>
          </ul>
        </div>
        <div>
          <h3>Seasons</h3>
          <ul>
            <li><Link className="nav-link" to="/season/manage">Create a new season</Link></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

const SeasonSummaryByWeek = () => {
  const [resultsByWeek, setResultsByWeek] = useState()
  const sessionData = sessionManager.getSession()
  const isMounted = useIsMountedRef()

  useEffect(() => {
    const onGet = (response) => {
      if(isMounted.current === true) {
        setResultsByWeek(response)
      }
    }

    const onGetError = (error) => {
      console.log(error)
    }

    get(`/api/picks/summary/game/season/${sessionData.season.seasonId}/user/${sessionData.userData.userId}`,
      onGet,
      onGetError,
    )
    return () => { isMounted.current = false }

  },[])

  return (
    <div className="callout">
      <h2>Your weekly summary</h2>
      <p>Click a week to see your picks</p>
        {
          resultsByWeek &&
            <BarChart data={resultsByWeek} />
        }

    </div>
  )
}


export default PicksMain