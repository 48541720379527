import React, {useState } from "react"
import sessionManager from '../../lib/session'
import Alert from '../../common/components/Alert'
import FormField from '../../common/components/FormField'
import SetEmail from '../../common/components/SetEmail'
import SetName from '../../common/components/SetName'
import SubmitButton from '../../common/components/SubmitButton'
import {post} from '../../lib/call-api'

const SaveMember = ({onSuccess, playerData}) => {
  const session = sessionManager.getSession()
  const [errorMessage, setErrorMessage] = useState('')
  const originalEmailAddress = (playerData) ? playerData.email : ''
  const [emailAddress, setEmailAddress] = useState(originalEmailAddress)

  const originalFirstName = (playerData) ? playerData.firstName : ''
  const originalLastName = (playerData) ? playerData.lastName : ''
  const originalFullName =  `${originalFirstName} ${originalLastName}`
  const originalNickname = (playerData) ? playerData.nickname : ''

  const [firstName, setFirstName] = useState(originalFirstName)
  const [lastName, setLastName] = useState(originalLastName)
  const [nickname, setNickname] = useState(originalNickname)
  const [assignmentData, setAssignmentData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [sendReset, setSendReset] = useState(false)
 
  const validateForm = () => {
    if(firstName === '' || lastName === '' || emailAddress === '') {
      setErrorMessage('Please provide a name and email address.')
      return false
    }
    return true
  }

  const reset = () => {
    setEmailAddress('')
    setLastName('')
    setFirstName('')
    setNickname('')
    setErrorMessage()
    setAssignmentData({})
    setIsLoading(false) 
    setSendReset(true)
  }

  const onPost = (resp) => {
    if (typeof onSuccess !== "undefined") {
      onSuccess(
        resp.memberDetail,
        `${resp.memberDetail.nickname} has been saved.`
      )
    }
    reset()
    return resp
  }
  
  const handleSubmit = async event => {
    event.preventDefault()
    const postBody = {
      "poolId": session.pool.poolId,
      "memberData": {
        "userId": (playerData) ? playerData.userId : null,
        "email": emailAddress,
        "originalEmail": originalEmailAddress,
        "firstName": firstName,
        "lastName": lastName,
        "fullName": `${firstName} ${lastName}`,
        "originalFullName": originalFullName,
        "nickname": nickname,
        "originalNickName": originalNickname,
       

        "status": (playerData) ? playerData.status : 'active',
        
      } 
    }
    post("/api/users/save",onPost,setErrorMessage,setIsLoading,'save this song',validateForm,postBody)
  }

  return (
    <form onSubmit={handleSubmit}>
        {
        !playerData && 
        <h2>Add a Member</h2>
      }
      <Alert type='error' message={errorMessage} />

      <section>
        
        <div className="form-group">
          <label>Nickname</label>
          <FormField value={nickname} handleChange={setNickname} />
        </div>

        <SetName values={[firstName,lastName]} setValue={[setFirstName,setLastName]} />
        <SetEmail passedValue={emailAddress} setValue={setEmailAddress} />
      </section>

    
      <section className="button-container">
        <SubmitButton isLoading={isLoading} />  
        
      </section>
    </form>
  )
}

export default SaveMember