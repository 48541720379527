import React,{useEffect, useState} from 'react'
import {get,post} from '../lib/call-api'
import sessionManager from '../lib/session'
import Alert from '../common/components/Alert'

import SubmitButton from '../common/components/SubmitButton'

// setSessionUpdated is passed in from Authentication route to let this component tell the navbar that the session was just updated
// so that the new season and current week is shown
const ManageSeason = ({setSessionUpdated}) => {
  const sessionData = sessionManager.getSession()
  const [errorMessage,setErrorMessage] = useState(null)
  const [successMessage,setSuccessMessage] = useState(null)
  const [isLoading,setIsLoading] = useState(false)
  const currentYear = new Date().getFullYear()
  const [sportsLeagues,setSportsLeagues] = useState()
  const [selectedLeague,setSelectedLeague] = useState()

  useEffect(() => {
    const onGet = (response) => {
      
      setSportsLeagues(response.sportsLeagues)
      if(response.sportsLeagues.length === 1) {
        console.log(response.sportsLeagues[0])
        setSelectedLeague(response.sportsLeagues[0])
      }
    }
    const onError = (error) => {
      // do nothing for now
    }

    get('/api/sports-league/list',
      onGet,onError,setIsLoading,'get a list of sports leagues'
    )
  },[])
  
  
  const handleSubmit = (event) => {
    event.preventDefault()
    setIsLoading(true)
    const onPost = (response) => {
      setErrorMessage(null)
      setSuccessMessage("Successfully created season.")
      if(response.season && response.weeks && response.weeks.length > 0) {
        sessionManager.updateSession(
          {
            "season": response.season,
            "thisWeek": response.weeks[0]
          }
        )
        setSessionUpdated(true)
      }
      
    }
    const onError = (error) => {
      console.log(error)
      setSuccessMessage(null)
      setErrorMessage(`We couldn't create the season. ${error}`)
    }

    const requestBody = {
      "poolId": sessionData.pool.poolId,
      "seasonYear": currentYear,
      "seasonType": "REG",
      "sportsLeagueId": selectedLeague.sportsLeagueId
    }

    post('/api/schedule/season/create', onPost, onError, setIsLoading, 'create the season', () => {return true},requestBody)

  }
  return (
    <>
    <h1>Manage Seasons</h1>
      {
        sportsLeagues && sportsLeagues.length === 0 &&
        <div className="no-results">There are not any sports leagues in the system.</div>
      }

      {
        sportsLeagues && sportsLeagues.length > 0 && selectedLeague &&
        <>
          <p className="intro">To create an {selectedLeague.abbreviation} season for {currentYear}, click the button below.</p>
          {
              successMessage === null &&
              <>
              {
                errorMessage !== null &&
                <Alert type="error" message={errorMessage} />
              }
              <form onSubmit={(event) => handleSubmit(event)}>
                <div className="button-container">
                  <SubmitButton actionTextOverride="Create Season" isLoading={isLoading}/>
                </div>
              </form>
            </>
          }
        </>
      }
      { 
        successMessage !== null && 
        <Alert type="success" message={successMessage} />
      
      }

    </>
  )
}

export default ManageSeason