import React, {useEffect,useState} from 'react'
import TeamIcon from '../../teams/components/TeamIcon'
import DisplaySpread from '../../games/components/DisplaySpread'
import './GamePicksSummaryByWeek.scss'
import sessionManager from '../../lib/session'
import DisplayDate from '../../common/components/DisplayDate'
import DisplayTime from '../../common/components/DisplayTime'
import WeekList from '../../common/components/WeekList'
import {get} from '../../lib/call-api'
import useIsMountedRef from '../../common/hooks/useIsMountedRef'
import { useHistory } from 'react-router-dom';

const GamePicksSummaryByWeek = ({preSelectedWeekId}) => {
  const sessionData = sessionManager.getSession()
  const [picksData,setPicksData] = useState([])
  const [gamesWithPicks, setGamesWithPicks] = useState([])
  
  // the WeekList component expects a fully formed week object as the "selectedWeek" but only actually needs a weekId
  // create an object we can pass in by default so the weekId in the URL is pre-selected in the drop down 
  const defaultWeekToShow = (preSelectedWeekId) ? {
    "weekId": preSelectedWeekId
  } :
  sessionData.thisWeek

  const [weekIdToShow,setWeekIdToShow] = useState(preSelectedWeekId)
  const [weekToShow,setWeekToShow] = useState(defaultWeekToShow)
  const isMounted = useIsMountedRef()

  const [gamesWithWinners,setGamesWithWinners] = useState([])

  const history = useHistory()

   useEffect(() => {
    let weekUrlString = ''
    if(weekIdToShow) {
      weekUrlString = `/week/${weekIdToShow}`
    } else {
      setWeekToShow(sessionData.thisWeek)
      weekUrlString = `/week/${sessionData.thisWeek.weekId}`
    }

    const onGet = (response) => {
      if(isMounted.current === true) {
        setPicksData(response.picks)
        setGamesWithPicks(() => response.picks.filter(pick => pick.selectionId !== null))
        setGamesWithWinners(() => response.picks.filter(pick => pick.winningTeamId !== null))
        
        if((!preSelectedWeekId && weekIdToShow) || weekIdToShow !== preSelectedWeekId) {
          history.push(`/picks/history/${weekIdToShow}`) 
        }
      }
    }

    const onError = (customError) => {
      console.log(customError)
    }
    get(`/api/picks/game/user/${sessionData.userData.userId}${weekUrlString}`,onGet,onError,null,'get the picks for this season')
    
    return () => { isMounted.current = false}
  },[weekIdToShow, sessionData.userData.userId])

  // since we are passing the weekId to the API and the drop down is passing us a full week object
  // update the current weekId so the API is called again
  useEffect(() => {
    if(weekIdToShow !== weekToShow) {
      setWeekIdToShow(weekToShow.weekId)
    }
  },[weekToShow])

  const cssClassResult = (pickData) => {
    if(pickData.winningTeamId !== null) {
      if (pickData.selectionId !== null && pickData.selectionId === pickData.winningTeamId) {
        return 'correct'
      } else if(pickData.selectionId !== null && pickData.selectionId !== pickData.winningTeamId) {
        return 'incorrect'
      }
    }
}

  return (
      <>
      {
        picksData.length > 0 && gamesWithPicks.length === 0 && 
        <div className='intro no-results'>You haven't made any picks for &nbsp; 
          <WeekList seasonId={sessionData.season.seasonId} handleChange={setWeekToShow} selectedWeek={weekToShow} allowAll={false}/>
        </div>
      }   
      {       
        picksData.length > 0 && gamesWithPicks.length > 0 && 
        <>
         <div className="intro">
          {
            gamesWithWinners.length === 0 &&
            <>No results yet for this week. Your picks are below for &nbsp;</>
          }
           {
            gamesWithWinners.length > 0 &&
            <>You picked {picksData.filter(pick => pick.selectionId === pick.winningTeamId).length} of {picksData.length} games correctly in &nbsp;</>
          }

          
          <WeekList seasonId={sessionData.season.seasonId} handleChange={setWeekToShow} selectedWeek={weekToShow} allowAll={false}/>
          </div>
          
          <table className='picks-summary'>
            <thead>
              <tr>
                <th>Home</th>
                <th>Spread</th>
                <th>Away</th>
                <th>Date &amp; time</th>
              </tr>
            </thead>
            <tbody>
              {
              picksData.map(pick => 
              <tr key={`pick-game-${pick.gameId}`}>
              
                <td className={cssClassResult(pick)}>
                  <TeamIcon teamToDisplay={pick.home} isSelected={pick['selectionId'] === pick.home.teamId} />
                </td>
                <td> <DisplaySpread spread={pick.spread} /></td>
                <td className={cssClassResult(pick)}>
                  <TeamIcon teamToDisplay={pick.away} isSelected={pick['selectionId'] === pick.away.teamId} />
                </td>
                <td><DisplayDate dateToFormat={pick.dateTime} /> at <DisplayTime toFormat={pick.dateTime} /> </td>
              </tr>
              )
            }
            </tbody>
          </table>
        </>
      }
      </>
  )

}


export default GamePicksSummaryByWeek