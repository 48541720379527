import React from 'react'

const StatusItem = (props) => {
  let className = 'status'
  let iconClassName
  if(props.isOk === true) {
    iconClassName = 'fas fa-check-circle'
  } else {
    iconClassName = 'fas fa-exclamation-circle'
  }
  return (
    <li className={className}>
      <i className={iconClassName}></i>
      {props.children}</li>
  )
}

export default StatusItem