import React from "react"
import sessionManager from '../../lib/session'

const ActiveSeasonCheck = (props) => {
  const sessionData = sessionManager.getSession()
  return (
    <>
    {
      !sessionData.thisWeek.weekNum && (!props.bypass || props.bypass === false) &&
      <div class="no-active-week">
        <p>There is not currently an active season.</p>
        <p>Please check back later.</p>
      </div>
    }
    {
     ( sessionData.thisWeek.weekNum || props.bypass === true) && 
      <>{ props.children }</>
    }
    </>
  )
}

export default ActiveSeasonCheck