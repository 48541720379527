import React,{useState, useEffect} from 'react'
import sessionManager from '../lib/session'
import WeekList from '../common/components/WeekList'
import {get} from '../lib/call-api'
import StatusItem from '../common/components/StatusItem'
import useIsMountedRef from '../common/hooks/useIsMountedRef'

const StatusReport = () => {
  const [reportData,setReportData] = useState([])
  const sessionData = sessionManager.getSession()
  const [weekToShow,setWeekToShow] = useState()
  const [usersWithPicks,setUsersWithPicks] = useState()
  const isMounted = useIsMountedRef()

  
  useEffect(() => {
    const onGet = (response) => {
      if(isMounted.current === true) {
        setReportData(response.report)
        setUsersWithPicks(() => response.report.filter(user => user.percentOfPicks === 1))
      }
      
    }
  
    
    if(weekToShow) {
      get(`/api/picks/status/game/week/${weekToShow.weekId}`,onGet)
    }

    return () => { isMounted.current = false }
  },[weekToShow, isMounted])

  useEffect(() => {
    setWeekToShow(sessionData.thisWeek)
  }, [sessionData.thisWeek, setWeekToShow])

  const summaryText = () => {
    if(usersWithPicks.length === 0) {
        return `Zero players `
    } else if(usersWithPicks.length === reportData.length) {
      return `All players `
    } else {
      return `${usersWithPicks.length } of ${reportData.length} players `
    }
  }
  return (
  <div>
    
    {
      weekToShow && 
      <>
      <h2>Status of Picks for Week {weekToShow.weekNum}</h2>

      <section className="week-list-container">
        <label>Select a week:</label> <WeekList seasonId={sessionData.season.seasonId} handleChange={setWeekToShow} selectedWeek={weekToShow}/>
      </section>

      {
        reportData && reportData.length === 0 && 
        <p>A status report is not available for the selected week</p>
      }

      {
        reportData && reportData.length > 0 && usersWithPicks &&
      <>
      <div className="callout">
        <p className="intro">{summaryText()} have made their picks for week {weekToShow.weekNum}</p>
        <ul className="status-report-list">
        {
                reportData.map(reportRow => 
                  <StatusItem isOk={reportRow.percentOfPicks === 1 } key={`status-for-${reportRow.user.nickname}`}>
                    {reportRow.user.nickname}
                    <div className="status-detail">{reportRow.numberOfPicks} of {reportRow.numberOfGames} picks</div>
                  </StatusItem>
                
                )
              }

          </ul>

      </div>
       
      </>
    }
    </>
  }
  </div>
)
}

export default StatusReport