import React,{useEffect,useState} from 'react'
import DisplayGame from './DisplayGame'
import DisplayDate from '../../common/components/DisplayDate'
import useIsMountedRef from '../../common/hooks/useIsMountedRef'

const GameList = React.memo(props => {
  const {games, userAction, onTeamSelect, onTotalPointsEntry, onSpreadChange, onIsTiebreakerChange, selectedTeamIdAttribute} = props
  const [displayData,setDisplayData] = useState([])
  const isMounted = useIsMountedRef()

  const matchDay = (date1,date2) => date1.getDay() === date2.getDay() && date1.getMonth() === date2.getMonth() && date1.getYear() === date2.getYear()

  useEffect(() => {
   
    if(isMounted.current === true) {
     
      const uniqueDays = [] 
      games.forEach(game => {
        const asDate = new Date(game.dateTime)
        const matches = uniqueDays.filter(uniqueDay => matchDay(uniqueDay,asDate))
        if(matches.length === 0) uniqueDays.push(asDate)
      })

      const dates = uniqueDays.map(uniqueDateTime => {
        const gamesOnDay = games.filter(game => matchDay(new Date(game.dateTime),uniqueDateTime))
        
        return {
          date: uniqueDateTime,
          games: gamesOnDay
        }
      }) 
      setDisplayData(dates)
    }
    return () => { isMounted.current = false }
    
  },[games,isMounted])
  
  return (
    <>
      {
        displayData.map(date => 
          <section key={`date-section-${date.date}`}>
          <h2><DisplayDate dateToFormat={date.date} displayFormat='full' /></h2>
          <div className='games-list'>
          {
            date.games.map(game => 
              <DisplayGame 
                key={`game-${game.gameId}`} 
                game={game} 
                userAction={userAction}
                onTeamSelect={onTeamSelect} 
                selectedTeamIdAttribute={selectedTeamIdAttribute}
                onTotalPointsEntry = {onTotalPointsEntry}
                onSpreadChange = {onSpreadChange}
                onIsTiebreakerChange={onIsTiebreakerChange}
                />
            )
          }
          </div>
          </section>
        )
      }
     
    </>
  )
})

export default GameList