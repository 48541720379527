import React, {useState} from 'react';
import { withRouter } from "react-router-dom"
import Routes from "./routes";
import './common/css/App.scss';
import { ModalProvider  } from './common/components/Modal.Context'

import ReactGA from 'react-ga'
ReactGA.initialize('UA-12860677-2')

function App() {
  const childProps = {}
    
  // sessionUpdated tells the Routes if one of the components updated the session
  // if its set to true, the navigation bar will refresh to show the new data.
  // an example scenario is: when a user creates a new season, we want that new season to be used.


  // setSessionUpdated is passed to any component that can update a session.
  // in the example above, setSessionUpdated is passed to season/manage.js so when we get a successful
  // response that a new season was created, we can trigger the navbar to be updated
  const [,setSessionUpdated] = useState(false)
  
  return (
    <ModalProvider> 
          <Routes childProps={childProps} setSessionUpdated={setSessionUpdated} />
      <div id="modal-container"></div>
    </ModalProvider>  
  );
}

export default withRouter(App)