import React,{useState,useEffect} from 'react'
import sessionManager from '../lib/session'
import User from '../common/components/User'
import Rank from '../common/components/Rank'
import '../common/css/tables.scss'
import {get} from '../lib/call-api'
import useIsMountedRef from '../common/hooks/useIsMountedRef'


const Standings = () => {
  const [standingsData, setStandingsData] = useState([]) 
  const sessionData = sessionManager.getSession()
  const [mostRecentWeek,setMostRecentWeek]= useState()
  const [weeklySummary, setWeeklySummary] = useState([])
  const isMounted = useIsMountedRef()

  useEffect(() => {
    const onGet = (response) => {
      if(isMounted.current === true) {
        setStandingsData(response.standingsDetail.resultsByUser)
        setWeeklySummary(response.standingsDetail.weeklySummary)
      }
    }

    const onGetError = (customError) => {
      console.log(customError)
    }

    get(`/api/standings/detail`,onGet,onGetError)

    return () => { isMounted.current = false}

  },[isMounted])

  useEffect(() => {
      for(let i=weeklySummary.length-1;i>=0;i--) {
        if(weeklySummary[i].winners.length > 0) {
          setMostRecentWeek(weeklySummary[i])
          break
        }
      }
  },[weeklySummary])

  const getClassName = (user) => {
    if(parseInt(user.userId) === parseInt(sessionData.userData.userId)) return 'current-user'
  }

  const getCurrentUserCorrect = (weekId) => {
    
    const userData = standingsData.filter(user => user.userId === parseInt(sessionData.userData.userId))
    if(userData.length === 1) {
      return userData[0].games.weeklyTotals.filter(week => week.weekId === weekId)[0].totalCorrect
    } else {
      return 'na'
    }
  }

  const getLosersOfTieBreaker = (tiedUsers, winners) => {
   
    return tiedUsers.filter(user => {
      return winners.some(user2 => user2.userId !== user.userId)
    })
  }
  
  const getLastWeek = (allWeeksForUser) => {
    if(allWeeksForUser.length ===0) {
      return {
        "totalCorrect": 0,
        "winnings": 0
      }
    } else {
      return allWeeksForUser[allWeeksForUser.length-1]
    }
   
  }
  return (
    <>
    <h1>Standings &amp; Results  
{
        mostRecentWeek &&
        <span> through week {mostRecentWeek.weekNum}</span>
    }
    </h1>
      {

          !mostRecentWeek && 
          <div className="no-results">There are no results to show yet for this season.</div>
      }
      {
        mostRecentWeek && 
        <>
        {/*
        <WeekSummary weekToUse={mostRecentWeek} currentUser= {sessionData.userData} allData={standingsData} />
        */
      }
 
      <section>
       

        <table className="table table-striped ">
          <thead className="thead-dark">
            <tr>
              <th rowspan="2" className="grouped-alt">Rank</th>
              <th rowspan="2" className="grouped-alt">Player</th>
             
              <th colspan="4" className="grouped-alt">Season</th>
              <th colspan="2">Last week</th>
              
            </tr>
            <tr>
              
             
              <th className="border-override grouped-alt">Picks correct</th>
              <th className="grouped-alt">Games Behind</th>
              <th className="grouped-alt">Winnings</th>
              <th className="grouped-alt">Quiz correct</th>
              <th>Picks correct</th>
              <th>Winnings</th>
            </tr>
          </thead>

          <tbody>
          {
            standingsData.map(
              user => 
              <tr className={getClassName(user)} key={`standings-user-${user.userId}`}>
                <td><Rank rank={user.games.summary.rank } /></td>
                <td><User userData={user}/></td>
                <td>{user.games.summary.totalCorrect }</td>
                <td>{user.games.summary.gamesBehind }</td>
                <td>{user.games.summary.totalWinnings || 0}</td>
                <td>{ user.quiz.summary.totalPoints  || 0}</td>
                <td>{getLastWeek(user.games.weeklyTotals).totalCorrect}</td>
                <td>{getLastWeek(user.games.weeklyTotals).winnings}</td>
              </tr>
            )
          }
          </tbody>
        </table>
      </section>
      </>
      }

    </>
  )
}

const WeekSummary = ({weekToUse, currentUser, allData}) => {

    const userGamesCorrect = allData.filter(user => user.userId === currentUser.userId)[0].games.weeklyTotals.filter(week => week.weekId === weekToUse.weekId)[0].totalCorrect
    
    const userQuizCorrect = allData.filter(user => user.userId === currentUser.userId)[0].quiz.weeklyTotals.filter(week => week.weekId === weekToUse.weekId)[0].totalCorrect
    
    return (
      <section>
        <h3>Week {weekToUse.weekNum} Summary</h3>
        <div className="grid-container col-1-2">
          <div className="col">
              <WinnerBlock weekData={weekToUse} currentUser={currentUser} />
            </div>
            <div className="col">
              <h4>Your results</h4>
              <div className="grid-container col-1-1">
                <div className="col card">
                  You had <div className="count-correct"><div className="info-graphic">{userGamesCorrect}</div>games correct</div>
                  <p>Average was {weekToUse.avgPicksCorrect}</p>
                </div>
                <div className="col card">
                  You received <div className="count-correct"><div className="info-graphic">{userQuizCorrect | 0}</div>quiz points</div>
                </div>
              </div>  
            </div>
        </div>
      </section>
    ) 
}

const WinnerBlock = ({weekData,currentUser}) => {
  
  const numberOfWinners = weekData.winners.length
  const userIsAWinner = weekData.winners.filter(winner => winner.userId === currentUser.userId).length > 0
  
  let winnersHeaderText
  let otherWinners = []
  if(userIsAWinner === true) {
    if(numberOfWinners === 1) {
      winnersHeaderText = 'You won!'
    } else {
      winnersHeaderText = `You tied for the win! ${numberOfWinners}`
      otherWinners = weekData.winners.filter(winner => winner.userId !== currentUser.userId)
    }
  } else if (numberOfWinners === 1) {
    winnersHeaderText = 'Winner this week...'
  } else {
    winnersHeaderText = 'Winners this week...'
  }

 
  return (
    <>
    <h4>{winnersHeaderText}</h4>

    {
      userIsAWinner === false && 
      
        <div className="card"><div className="info-graphic">{weekData.winners[0].totalCorrect}</div>correct
        {
          weekData.winners.map(winner => 
            <User userData={winner} key={`winners-${winner.userId}`}/>
          )
        }
      </div>    
    }
    {
      userIsAWinner === true && 
      <div className="grid-container col-1-1">
        <div className="col card"><div className="info-graphic">+10</div>winnings</div>
        <div className="col card"><div className="info-graphic">25</div>total winnings</div>
      </div>
    }


    {
      userIsAWinner === true && otherWinners.length > 0 &&
      <div>
        Other winners:
        {
          weekData.winners.filter(winner => winner.userId !== currentUser.userId).map(winner => 
            <User userData={winner} key={`winners-${winner.userId}`}/>
          )
        }
      </div>
    }
    </>
  )
}


export default Standings