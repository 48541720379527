import React,{useState,useEffect} from 'react'
import utils from '../lib/util'
import DisplayQuestion from './components/DisplayQuestion'
import './css/quiz-selecting.scss'
import sessionManager from '../lib/session'
import {get,post} from '../lib/call-api'
import useIsMountedRef from '../common/hooks/useIsMountedRef'

const PickQuiz = () => {
  const [quizQuestions,setQuizQuestions] = useState([])
  const sessionData = sessionManager.getSession()
  const [isLoading, setIsLoading] = useState(true)
  const [userAnswers, setUserAnswers] = useState([])
  const [status, setStatus] = useState('editing')
  const [currentQuestion, setCurrentQuestion] = useState()
  const [answeredQuestions,setAnsweredQuestions] = useState([])
  const [resultSummary,setResultSummary] = useState(0)
  const thisWeek = sessionData.thisWeek
  const isMounted = useIsMountedRef()  
  useEffect(() => {
    const onGet = (response) => {
      if(isMounted.current === true) {
        if(response.quiz.quizQuestions && response.quiz.quizQuestions.length > 0) {
          setIsLoading(false)
          setQuizQuestions(response.quiz.quizQuestions)
          setCurrentQuestion(response.quiz.quizQuestions[0])
        } else {
          setIsLoading(false)
          throw new Error('No quiz questions were found.')
        }
      }
      
    }

    get(`/api/quiz/questions/week/${thisWeek.weekId}/user/${sessionData.userData.userId}`,onGet,null)
    return () => { isMounted.current = false}

  },[thisWeek,isMounted])

  const getCurrentQuestionIdx = () => {
    return quizQuestions.findIndex(question => question.questionId === currentQuestion.questionId)
  }

  const setErrors = () => { }

  const submit = async (event) => {
    event.preventDefault()
    const onPost = (response) => {
      setStatus('saved')
      setAnsweredQuestions(response.picks)
      setResultSummary(response.resultSummary)
    }
    const onPostError = (error) => {
      setErrors([error.message])
    }

    const reqBody = {
      "picks": userAnswers,
      "pickType": "quiz"
    }

    post('/api/picks/save',onPost,onPostError,setIsLoading,'save your quiz',null,reqBody)

  }

  const setAnswer = (questionId,selectedAnswerId) => {
   
    setUserAnswers(utils.saveArray({"gameId": questionId, "selectionId" : selectedAnswerId},"gameId",userAnswers))
  }

  const changeQuestion = (direction) => {
    const curIndex = getCurrentQuestionIdx()
    setCurrentQuestion(quizQuestions[curIndex+direction])
    
  }
  const previous = (event) => {
    event.preventDefault()
    changeQuestion(-1)
  }
  const next = (event) => {
    event.preventDefault()
    changeQuestion(1)
    
  }

const getUserAnswer = (question) => {
  const thisQuestion = userAnswers.filter(answer => answer.gameId === question.questionId)
  if(thisQuestion.length === 1) {
    return thisQuestion[0].selectionId
  } else {
    return null
  }
} 

  return (
    <section>
      {
        isLoading === false && quizQuestions.length === 0 &&
        <div>
          <h1>Sorry...</h1>
          <p className="no-results">There are no quiz questions for you to answer at this time.</p>
        </div>
        
      }

      {
        isLoading === false && currentQuestion && status === 'editing' && 
        <>
          <h1>Take the Quiz</h1>
          <DisplayQuestion 
            ctr={getCurrentQuestionIdx()} 
            totalQuestions={quizQuestions.length}
            question={currentQuestion} 
            onAnswerSelect={setAnswer} 
            selectedAnswerId={getUserAnswer(currentQuestion)} />

          <div className="button-container previous-next">
            <div>
            {
              getCurrentQuestionIdx() > 0 && quizQuestions.length > 1 && 
              <button className='btn btn-primary btn-next' onClick={(event) => {previous(event)}}>Previous question</button>
            }
            </div>
            <div>
            {
              getCurrentQuestionIdx() < quizQuestions.length-1 && 
              <button  className='btn btn-primary btn-previous' onClick={(event) => {next(event)}}>Next question</button>
            }

            {
              getCurrentQuestionIdx() === quizQuestions.length-1 && 
              <button className='btn btn-primary btn-previous' onClick={(event) => {submit(event)}}>See Your Results</button>
            }
            </div>
          </div>
         
         
        </>
      }

      {
        isLoading === false && status === 'saved' && 
          <>
          <h1>Quiz Results</h1>
          <DisplayResults answeredQuestions={answeredQuestions} resultSummary={resultSummary} checkAnswer={getUserAnswer}/>
          </>

      }
    </section>
  )
}

const DisplayResults = ({answeredQuestions,resultSummary,checkAnswer}) => {
  
  const pct = resultSummary.totalPointsAwarded / resultSummary.maxPointsPossible
  let resultSummaryText
  if(pct === 1) {
    resultSummaryText = 'Hey smarty pants.'
  } else if(pct === 0) {
    resultSummaryText = 'Better luck next time.'
  } else  {
    resultSummaryText = 'Not too shabby.'
  }

  return (

    <div className='quiz-summary-result'>
      <p><strong>{resultSummaryText}</strong> You received {resultSummary.totalPointsAwarded} of a possible {resultSummary.maxPointsPossible} points</p>
      {
        answeredQuestions.map((question,questionCtr) => 
          <DisplayQuestion question={question} ctr={questionCtr} selectedAnswerId={checkAnswer(question)} key={`result-question-${question.questionId}`}/>
        )
      }
    </div>
  )
}



export default PickQuiz