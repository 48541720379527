export const toggleNavDisplay = () => {
  const matches = document.getElementsByClassName('navbar-vertical')
  if(matches.length > 0) {

    for(let i=0;i<matches.length;i++) {
      const existing = matches[i]
      console.log('before',existing.className)

      existing.classList.toggle('expanded')
     
      console.log('after',existing.className)

    }
  }

}