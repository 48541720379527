import React, { useState, useEffect } from 'react'
import { Route, Redirect,Link } from 'react-router-dom'
import NavigationBar from './NavigationBar'
import NoActiveSeason from './NoActiveSeason'
import sessionManager from '../../lib/session'
import ReactGA from 'react-ga'
import {toggleNavDisplay} from '../../lib/navigation'

const pageViewTracking = (pathName) => {
  ReactGA.pageview(pathName)
}

// setSessionUpdated is passed in from routes.js to let components tell other components that session was just updated
// see the note in App.js for a scenario
export default function AuthenticatedRoute({ component: C, accessLevel, bypassActiveSeasonCheck, setSessionUpdated, ...rest }) {
  const [isLoading,setIsLoading] = useState(true)
  const [isAuthenticated,setIsAuthenticated] = useState(null)
  const [isAuthorized,setIsAuthorized] = useState(null)
  const checkAuthorization = (userData) => {
    if(userData === null || typeof(userData) === "undefined") {
      setIsAuthorized(false)
    } else if(accessLevel === 'active' && userData.status !== 'active') {
      setIsAuthorized(false)
    } else {
      setIsAuthorized(true)
    }
  }
  
  useEffect(() => {
    setIsLoading(true)
    const sessionData = sessionManager.getSession()
    const accessToken = sessionData.accessToken
    const tokenIsValid = (accessToken !== null && typeof(accessToken) !== "undefined")

    pageViewTracking(rest.path)

    sessionManager.refreshSession(true).then(() => {
      checkAuthorization(sessionData.userData)
      setIsAuthenticated(tokenIsValid)
      setIsLoading(false)
    }).catch(e => {
      sessionManager.clearSession()
    })
   

  },[rest.path])
  
  const defaultExpandedNavClass = 'navbar-vertical'
  

  return (
     isLoading === false &&
      <Route
        {...rest}
        render={props => {
          if(isAuthenticated === true) {
            if(isAuthorized === true) {
                    
              return (
                
                 <div id="logged-in-container">
                  <header className={defaultExpandedNavClass}>
                    <Link to="#" onClick={() => {toggleNavDisplay()}} id="hamburger"><i className='fa fa-bars'></i></Link>
                    <Link to="/"><img src="/img/logo-horiz.png" alt="Bunk's Pool. Since 1973." id="nav-logo" /></Link>
                  </header>
                    
                  <NavigationBar className={defaultExpandedNavClass} setSessionUpdated={setSessionUpdated} />
                  
                  <main role="main" className={defaultExpandedNavClass}>
                    <NoActiveSeason bypass={bypassActiveSeasonCheck}>
                      <C {...props} setSessionUpdated={setSessionUpdated} />
                    </NoActiveSeason>
                  </main>
                </div>
              )
            } else {
              return (
                <div>Sorry you don't have access</div>
              )
            }

          } else {
            return (<Redirect to={`/login?redirect=${props.location.pathname}${props.location.search}`} />)
          }
        }
      }  
    />
  )
}

