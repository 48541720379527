import React from 'react'
import {Link} from 'react-router-dom'

const QuizMain = () => {
  
    return (
    <>
    <h1>Sports Quiz</h1>
      <div className="grid-container same-height col-1-1">
       
          <AdminOnlyContent />
          <div className="callout">
            <Link to="/quiz/picks/select">Take the sports quiz</Link>
          </div>
       
      </div> 
   
    </>
  )
}

const AdminOnlyContent = () => {
  return (
    <div className="callout prominent">
      <h2>Admin only</h2>
          <ul>
            <li><Link className="nav-link" to="/quiz/manage">Manage the sports quiz</Link></li>
          </ul>
    </div>
  )
}

export default QuizMain