import React, {useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import { Editor } from '@tinymce/tinymce-react'
import SubmitButton from '../common/components/SubmitButton'
import Alert from '../common/components/Alert'
import DisplayPost from './components/DisplayPost'
import { get,post } from '../lib/call-api'
import {cleanHtml} from './common'
import './CommishNotes.scss'

const ManagePost = (props) => {
  const {postId} = props.match.params

  const [postText,setPostText] = useState((postId) ? null : '<div></div>' )
  const [postDateAdded,setPostDateAdded] = useState(null)
  const [postDateLastModified,setPostDateLastModified] = useState(null)
  const [originalPostText,setOriginalPostText] = useState()
  
  const [pageLevelError,setPageLevelError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [status,setStatus] = useState('editing')
  const [savedPost,setSavedPost] = useState({})

  const handleEditorChange = (text) => {
    setPostText(text)
  }

  const goToEditMode = () => {
    setSavedPost({})
    getDetails(postId)
    setStatus('editing')
  }

  const getDetails = (postIdToGet) => {
    get(`/api/commish-notes/post/${postIdToGet}`,onGet,onError,setIsLoading,'get the commish notes')
  }

  const onGet = (resp) => {
    const cleanedPostText = cleanHtml(`<div>${resp.post.postText}</div>`)
    setIsLoading(false)
    setPostText(cleanedPostText)
    setOriginalPostText(resp.post.postText)
    setPostDateAdded(resp.post.dateAdded)
    setPostDateLastModified(resp.post.dateLastModified)
  }
  const onError = (error) => {
    setIsLoading(false)
    setPageLevelError(error)
  }
  

  useEffect(() => {
    if(postId) {
      getDetails(postId)
    }
    
  },[])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setPageLevelError('')

    const onPost = (response) => {
      setSavedPost(response.post)
      setIsLoading(false)
      setPageLevelError('')
      setStatus('saved')
    }

    const validateForm = () => {
      if(postText === '') {
        setPageLevelError('Please provide some text.')
        return false
      }
      return true
    }

    const reqBody = {
      "post": {
        "id": postId,
        "postText": postText,
        "dateAdded": postDateAdded,
        "dateLastModified": postDateLastModified
      }
    }
    post('/api/commish-notes/save',onPost,onError,setIsLoading,'save the commish notes',validateForm,reqBody)

  }

  

  const processHtml = (plugin, args) => {
    cleanHtml(args.node)
  }

  return (
    

    <div>
      <h1>Commish Notes</h1>
      
      {
        (savedPost.id || status === 'cancelled') && 
        <>
          {
            status === 'saved' && 
            <Alert type="success" message='Commish notes saved!' />
          }
            <div className="callout">
            <DisplayPost editAction={goToEditMode} passedPostData={savedPost} />  
            </div>
        </>
      }

      {
        !savedPost.id && status === 'editing' && postText &&
    
      <form onSubmit={handleSubmit}>
        
        <Alert type="error" message={pageLevelError}></Alert>
    
        <Editor
         apiKey='68nk9wwi8xtivwtmn7vvu7flppc7xvvqy7s3gzem8im8yjyr'
         value={postText.innerHTML}
         init={{
           height: 500,
           menubar: false,
           plugins: [
             'autolink lists paste'
           ],
           paste_postprocess: (plugin, args) => { processHtml(plugin, args) },
           paste_block_drop: true,
           paste_data_images: false,
           paste_enable_default_filters: false,
           paste_retain_style_properties: 'color font-size',
           paste_word_valid_elements: 'b,strong,i,em,h1,h2,ul,li,h3,p',
           toolbar:
             'undo redo | formatselect | bold italic | bullist numlist outdent indent | removeformat | help'
         }}
         onEditorChange={handleEditorChange}
       />

       <div className="button-container">
         <SubmitButton isLoading={isLoading}/>
         <Link to="/home">Cancel</Link>
       </div>
       </form>
       }
    </div>
)

}

export default ManagePost